var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pdf-container" }, [
    _vm.document
      ? _c(
          "div",
          { staticClass: "has-text-centered" },
          [
            _c("h3", { staticClass: "is-small-text has-text-centered" }, [
              _vm._v(_vm._s(_vm.document.name)),
            ]),
            _vm._v(" "),
            _vm.isLoading
              ? _c("loading", {
                  staticClass: "is-small",
                  attrs: { textInFront: "", "text-after": "" },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isLoading ? _c("canvas", { ref: "pdfCanvas" }) : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-delete",
                attrs: { title: "Löschen" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("delete", _vm.document)
                  },
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("delete")])]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }