import Vuex from "vuex";
import {axiosOptions} from "@api";
import {currentUser} from "@utilities/functions";

const microsoftStore = {
    state: {
        token: null,
        tokenExpiration: null,
    },
    actions: {
        getToken: ({state}) => {
            if(state.token || (state.tokenExpiration && state.tokenExpiration > Date.now() / 1000)) {
                return;
            }
            state.tokenExpiration = Date.now() / 1000 + 60;
            let user = currentUser();
            axiosOptions('users/' + user.id + '/get_token').then(response => {
                state.token = response.data;
            });
        }
    }
}

const store = new Vuex.Store({
    modules: {
        microsoftStore: microsoftStore,
    }
});

export { store };

export default store;
