import axios from "axios";
import {getAxios} from "@api";
import set from "lodash/set";
import get from "lodash/get";
import toNumber from "lodash/toNumber";

export default {
    state: {
        requestDetails: {
            isLoading: false,
            /*isExpanded: false,
            isExpandedMax: false,
            expansionHeight: '500px',*/
            dataHasChanged: false,
            dataIsSaving: false,
            _custom_groups: ['request_details', 'tag_trait', 'money_read'],
        },

    },
    mutations: {

        updateRequestDetails: function (state, value) {
            state.requestDetails = {...state.requestDetails, ...value}
        },

        /*updateQuickPlaceholders: function (state, { dayID, placeholderType, placeholderID, placeholderData }) {
            const dayIndex         = state.order.days.findIndex(x => x.id === dayID);
            const placeholderIndex = state.order.days[dayIndex][placeholderType].findIndex(x => x.id === placeholderID);
            state.order.days[dayIndex][placeholderType].splice(placeholderIndex, 1, placeholderData);
        },*/
    },
    actions: {
        getHotelRequestDetails: function({state, _, rootState}) {
            this.commit('updateRequestDetails', {'isLoading': true});

            getAxios('hotel_requests', {
                'placeholder.day.order': rootState.orderID,
                '_custom_groups': state.requestDetails._custom_groups,
                '_order[placeholder.startAt]': 'ASC',
            }).then(response => {

                this.commit('updateRequests', {requests: response.data.map(hotel => {
                        let hb = get(hotel, 'requestDetailArray.halfBoard', null);
                        hotel = set(hotel, 'requestDetailArray.halfBoard', hb);
                        hb = toNumber(hb);
                        return {
                            ...hotel,
                            ...!hotel.helper && {
                                helper: {
                                    standardPrice: {
                                        room: toNumber(hotel.contingent.standardPrice.amount) ? (toNumber(hotel.contingent.standardPrice.amount) - hb) * 2 : null,
                                        person: toNumber(hotel.contingent.standardPrice.amount) ? (toNumber(hotel.contingent.standardPrice.amount) - hb) : null,
                                    },
                                    singlePrice: {
                                        room: toNumber(hotel.contingent.singlePrice.amount) ? (toNumber(hotel.contingent.singlePrice.amount) + toNumber(hotel.contingent.standardPrice.amount) - hb) : null,
                                    },
                                }
                            }
                        }
                    })
                })
                this.commit('updateRequestDetails', {'isLoading': false});

            }).catch(err => this.commit('updateRequestDetails', {'isLoading': false}))
        },

    },
    getters: {
        hotelRequestDetails: function(state, _, rootState) {
            let placeholders = []
            if(rootState.order && rootState.order.days) {
                rootState.order.days.forEach((day) => {
                    if(day.hotelPlaceholders ) {
                        day.hotelPlaceholders.forEach((hotelPlaceholder) => {
                            placeholders.push(hotelPlaceholder)
                        })
                    }
                })
            }
            return placeholders
        },


        requestDetails: function (state) {
            return state.requestDetails;
        }
    }
}
