<template>
    <div>
        <component :is="dropzone ? 'vue-dropzone' : 'div'"
            ref="dropzone"
            id="dropzone"
            :options="dropzoneOptions"
            useCustomSlot
            v-on:vdropzone-sending="sendingEvent"
            v-on:vdropzone-success="fileSuccess"
            v-on:vdropzone-complete="fileCompleted"
            v-on:vdropzone-files-added="filesAdded"
        >
            <div class="redesign-table" :style="hasBorder ? '' : 'border-top:0;'">
                <div class="table-wrapper">
                    <div class="row is-header" v-if="hasTitle">
                        <h2>{{title}}</h2>
                    </div>

                    <filters
                        v-if="hasFilter"
                        class="row is-filters"
                        v-model="filters"
                        v-bind:options="optionsDocuments"
                    ></filters>

                    <div class="row is-head" v-if="hasHead">
                        <div
                            class="cell is-one-third is-sortable"
                            v-on:click="sortBy('name')"
                            v-bind:class="columnClass('name')"
                            v-if="includes(columns, 'name')"
                        >
                            <i class="fa fa-file" aria-hidden="true"></i>
                            Dokumentenname
                        </div>

                        <div
                            class="cell is-smaller-cell"
                            v-if="includes(columns, 'filetype')"
                        >
                            <i class="fa fa-file-o" aria-hidden="true"></i>
                            Dateityp
                        </div>

                        <div
                            class="cell is-sortable"
                            v-on:click="sortBy('description')"
                            v-bind:class="columnClass('description')"
                            v-if="includes(columns, 'offerId')"
                        >
                            <i class="fa fa-hashtag" aria-hidden="true"></i>
                            OfferID
                        </div>

                        <div
                            class="cell is-sortable"
                            v-on:click="sortBy('description')"
                            v-bind:class="columnClass('description')"
                            v-if="includes(columns, 'description')"
                        >
                            <i class="fa fa-file-text" aria-hidden="true"></i>
                            Details
                        </div>

                        <div
                            class="cell is-sortable is-larger-cell"
                            v-on:click="sortBy('type')"
                            v-bind:class="columnClass('type')"
                            v-if="includes(columns, 'type')"
                        >
                            <i class="material-icons">help</i>
                            Kategorie
                        </div>

                        <div
                            class="cell"
                            v-if="includes(columns, 'reference')"
                        >
                            <i class="fa fa-link" aria-hidden="true"></i>
                            Bezug
                        </div>

                        <div
                            class="cell is-sortable"
                            v-on:click="sortBy('source')"
                            v-bind:class="columnClass('source')"
                            v-if="includes(columns, 'source')"
                        >
                            <i class="material-icons">local_library</i>
                            Quelle
                        </div>

                        <div
                            class="cell is-sortable is-days-cell"
                            v-on:click="sortBy('createdAt')"
                            v-bind:class="columnClass('createdAt')"
                            v-if="includes(columns, 'createdAt')"
                        >
                            <i class="material-icons">date_range</i>
                            Erstellungsdatum
                        </div>

                        <div
                            class="cell is-sortable"
                            v-on:click="sortBy('updatedAt')"
                            v-bind:class="columnClass('updatedAt')"
                            v-if="includes(columns, 'updatedAt')"
                        >
                            <i class="material-icons">date_range</i>
                            Änderungsdatum
                        </div>

                        <div class="cell is-250px-cell">
                            <i class="material-icons">assignment_returned</i>
                            Verfügbare Aktionen
                        </div>

                        <div class="cell is-actions"></div>
                    </div>

                    <loading v-if="isLoading" />

                    <template v-else>
                        <template v-if="documents.length > 0">
                            <div class="is-zebra">
                                <draggable
                                    :value="documents"
                                    :options="{
                                        draggable: '.document-row',
                                        group: 'document-group',
                                    }"
                                    >
                                <div
                                    v-for="document in documents"
                                    v-bind:key="document.id"
                                    class="row document-row"
                                    :class="fileType(document) === '.pdf' ? '' : 'no-drag'"
                                >
                                    <div class="cell is-one-third " v-if="includes(columns, 'name')">
                                        {{ document.name }}
                                    </div>

                                    <div class="cell is-smaller-cell hide-on-drag" v-if="includes(columns, 'filetype')">


                                            {{ fileType(document) }}

                                    </div>

                                    <div class="cell hide-on-drag" v-if="includes(columns, 'offerId')">
                                        {{ document.description }}
                                    </div>

                                    <div class="cell hide-on-drag" v-if="includes(columns, 'description')">
                                        {{ document.description }}
                                    </div>

                                    <div class="cell is-larger-cell hide-on-drag" v-if="includes(columns, 'type')">
                                        <div class="tags">
                                            <span class="tag is-white" v-if="document.type">
                                                {{ getValueWithKey({ key: document.type, optionList: optionsDocuments.types }) }}
                                                {{ document.year ? document.year : ''}}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="cell hide-on-drag" v-if="includes(columns, 'reference')">
                                        <template v-for="link in document.links">
                                            <span class="bordered-object" v-if="link.order">{{ '# ' + link.order.id  }}</span>
                                            <span class="bordered-object" v-else-if="link.client">{{ link.client.companyName }}</span>
                                            <span class="bordered-object" v-else-if="link.orderConcept">{{link.orderConcept.name  }}</span>
                                            <span class="bordered-object" v-else-if="link.provider">{{ link.provider.name }}</span>
                                            <span class="bordered-object" v-else-if="link.area">{{ link.area.name }}</span>
                                            <span class="bordered-object" v-else-if="link.destination">{{ link.destination.name }}</span>
                                            <span class="bordered-object" v-else-if="link.place">{{ link.place.name }}</span>
                                            <span class="bordered-object" v-else-if="link.agency">{{ link.agency.name }}</span>
                                            <span class="bordered-object" v-else-if="link.organisation">{{ link.organisation.name }}</span>
                                            <span class="bordered-object" v-else-if="link.bank">{{ link.bank.name }}</span>
                                        </template>
                                    </div>

                                    <div class="cell hide-on-drag" v-if="includes(columns, 'source')">
                                        {{ document.source }}
                                    </div>

                                    <div class="cell is-days-cell hide-on-drag" v-if="includes(columns, 'createdAt')">
                                        {{ document.createdAt | fullYearWithTime }}
                                    </div>

                                    <div class="cell hide-on-drag" v-if="includes(columns, 'updatedAt')">
                                        {{ document.updatedAt | fullYearWithTime }}
                                    </div>

                                    <div class="cell is-250px-cell hide-on-drag">


                                        <button
                                            v-if="document.cloudStorage && ['.odt', '.docx'].includes(fileType(document))"
                                            v-on:click="openWord(document.path)"
                                            class="tag button is-white"
                                            title="Word öffnen"
                                        ><i class="fa fa-file-word-o has-margin-right"></i> öffnen</button>
                                        <button
                                            v-if="document.cloudStorage && ['.xlsx', '.xlsm', '.xls'].includes(fileType(document))"
                                            v-on:click="openExcel(document.path)"
                                            class="tag button is-white"
                                            title="Excel öffnen"
                                        ><i class="fa fa-file-excel-o has-margin-right"></i> öffnen</button>
                                        <button
                                            v-if="fileType(document) === '.docx' || fileType(document) === '.xlsx' && document.cloudStorage"
                                            v-on:click="convertDocument(document.id)"
                                            class="tag button is-white"
                                            title="in PDF umwandeln"
                                        ><i class="fa fa-file-pdf-o has-margin-right"></i> umwandeln</button>
                                        <button
                                            v-if="fileType(document) === '.pdf'"
                                            v-on:click="newEmail(document)"
                                            class="tag button is-white"
                                            v-bind:class="{ 'is-loading': buttonIsLoading }"
                                            title="Email senden"
                                        ><i class="fa fa-file-pdf-o has-margin-right"></i> per Email versenden</button>
                                    </div>

                                    <div class="cell is-actions hide-on-drag">

                                        <button
                                            v-if="previewable(document)"
                                            title="Vorschau"
                                            v-on:click="previewDocument(document)"
                                        ><i class="material-icons">search</i></button>
                                        <update-popover
                                            v-bind:update="{updatedAt: document.updatedAt, updatedBy: document.updatedBy}"
                                            :api="'/api/documents/' + document.id"
                                        >
                                            <button
                                                class="btn-download"
                                                title="Letzte Aktualisierung"
                                            ><i class="material-icons">update</i></button>
                                        </update-popover>
                                        <a
                                            class="btn-download"
                                            title="Download"
                                            v-bind:href="downloadDocument(document)"
                                            target="_blank"
                                            download
                                        ><i class="material-icons">file_download</i></a>

                                        <button
                                            class="btn-edit"
                                            title="Bearbeiten"
                                            v-on:click="editDocument(document)"
                                        ><i class="material-icons">edit</i></button>

                                        <button
                                            class="btn-delete"
                                            title="Löschen"
                                            v-on:click="deleteDocument(document.id)"
                                        ><i class="material-icons">delete</i></button>
                                    </div>
                                </div>
                                </draggable>
                            </div>
                        </template>

                        <div class="row" v-else>
                            <div class="cell has-text-centered">
                                Keine Dokumente vorhanden
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div id="documentDropZone" class="dz-dropzone" v-if="dropzone">Dateien zum hochladen hierhin ziehen</div>
        </component>
        <div class="bp-panel mt-5" v-if="hasMergePanel">
            <div class="panel-content">
                <h1>PDFs zusammenführen</h1>
                Hier können PDF-Dokumente von hereingezogen, per Drag and Drop sortiert und zu einem PDF-Dokument zusammengeführt werden
                <hr>
                <draggable
                    element="div"
                    class="columns"
                    v-model="documentsToMerge"
                    v-bind:options="{
                        draggable: '.column',
                        group: 'document-group',
                    }"
                >

                    <div class="column is-narrow" v-for="(document,index) in documentsToMerge" :key="document.id">
                        <PdfViewer :document="document" @delete="deleteDocumentToMerge" @loaded="pdfData => document.pdfData = pdfData"></PdfViewer>
                    </div>
                    <div class="column" v-if="documentsToMerge.length === 0">
                        <div class="pdf-container is-empty">Dokumente hier hereinziehen</div>
                    </div>

                </draggable>
                <hr>
                <button class="button is-primary is-full-width" :disabled="documentsToMerge.length === 0" @click="createPDF">PDF erstellen</button>
            </div>
        </div>

        <form-document
            v-if="hasForm"
            ref="modal"
            v-bind:optionsDocuments="optionsDocuments"
            v-on:close="closeModal"
        />

        <new-email
            v-if="emailForm.isVisible"
            v-bind:preselected-order="preselectedOrder"
            v-bind:preselected-request="preselectedRequest"
            v-bind:preselectedContact="preselectedRequest ? null : emailForm.contact"
            v-bind:select-template="emailForm.template"
            v-bind:isModalVisible="emailForm.isVisible"
            v-bind:attach-document="emailForm.attachDocument"
            v-on:closeModal="emailForm.isVisible = false"
        ></new-email>

        <preview v-model="previewURL" />
    </div>
</template>



<script>
import includes      from 'lodash/includes';
import vue2Dropzone  from 'vue2-dropzone'

import Filters       from '@components/CRUD/Table/Filters';
import Loading       from '@components/Loading';
import Preview       from '@components/documents/Preview';
import { notifySuccess, notifyError } from '@components/Notification';
import { getValueWithKey, fullYearWithTime, authHeader, getFilePath, buildURLQuery, getPreviewFilePath } from '@utilities/functions';
import {getDocuments, optionsDocuments, deleteDocument, convertDocument, getRequest} from '@api';
import { PDFDocument } from 'pdf-lib';


import FormDocument  from './Form';
import NewEmail          from '@components/emails/modals/newEmail/index';
import UpdatePopover from "@components/UpdatePopover";
import Draggable from "@components/Draggable.vue";
import PdfViewer from "@components/PdfViewer";


export default {
    props: {
        hasForm:    { type: Boolean, default: true },
        hasTitle:    { type: Boolean, default: true},
        hasHead:    { type: Boolean, default: true},
        hasFilter:    { type: Boolean, default: true},
        hasBorder:    { type: Boolean, default: true},
        filterObject: {type: Object, default: () => {}},
        params:     { type: Array,   default: () => { return []; } },
        uploadData: { type: Object,  default: () => {} },
        defaultTypes: { type: Array, default: () => { return []; }},
        order : {type: Object, default: () => null},
        hasMergePanel: { type: Boolean, default: false},

        dropzone: { type: Boolean, default: true},

        columns: {
            type: Array,
            default: function () {
                return [
                    'name',
                    'filetype',
                    'type',
                    'createdAt'
                ]
            }
        },

        title: {
            type: String,
            default: "Dokumente"
        }
    },


    data: function () {
        return {
            documents: [],
            documentsToMerge: [],
            preselectedRequest: null,
            buttonIsLoading: false,
            optionsDocuments: {
                types: []
            },

            emailForm: {
                isVisible: false,
                contact: null,
                template: null,
                attachDocument: null
            },

            sort:   { key: '', reverse: false },
            apiParams: {
                filter: '',
                sorting: '',
                calculated: ''
            },

            isLoading: false,

            dropzoneOptions: {
                url: '/api/documents/upload',
                maxFilesize: 100,
                headers: authHeader(),
                previewTemplate: this.template(),
                createImageThumbnails: false,
                clickable: '#documentDropZone',
                paramName: "user_file_upload"
            },

            filters: {
                _search: {
                    value: ''
                },
                'startAt[after]': null,
                'startAt[before]': null,
                type: this.defaultTypes
            },

            queueSize: 0,
            editFile: {
                id: []
            },

            previewURL: null,
        }
    },

    computed: {
        preselectedOrder: function() {
            if(this.order){
                if(this.order.mainOffer && this.order.mainOffer.attachedOrders){
                    return this.order.mainOffer.attachedOrders.map(order => {
                        return typeof order === 'string' ? this.order : order;
                    }).sort((a,b) => a.id - b.id);
                } else {
                    return this.order;
                }
            }
            return null;
        }
    },


    methods: {
        openModal: function (data) {
            this.$refs.modal.openModal(data);
        },

        closeModal: function (data) {
            if (data) {
                this.apiCalls();
            }
        },

        newEmail: function(document) {
            this.buttonIsLoading = true;
            if (this.order && this.order.primaryOrderContact) {
                this.emailForm.contact = JSON.parse(JSON.stringify(this.order.primaryOrderContact));
            }
            if(document.type === 'room_list' && document.links[0] && document.links[0].request){

                getRequest(document.links[0].request.replace('/api/hotel_requests/', ''), {_groups: ['modal_read']}).then(response => {
                    this.preselectedRequest = response.data;
                    this.emailForm.isVisible = true;
                    this.buttonIsLoading = false;
                });
            } else {
                this.emailForm.isVisible = true;
                this.buttonIsLoading = false;
            }

            this.emailForm.attachDocument = document;
            this.emailForm.template = document.type;
        },

        openWord: function(path){
            window.location.href = 'ms-word:ofe|u|' + path
        },

        openExcel: function(path){
            window.location.href = 'ms-excel:ofe|u|' + path
        },

        convertDocument: function (id) {

                this.isLoading = true;

                convertDocument(id).then(response => {
                    notifySuccess('Das Dokument wurde in ein PDF umgewandelt!');
                    this.getDocuments();
                }, error => {
                    this.isLoading = false;
                    notifyError('Das Dokument konnte nicht umgewandelt werden! Server Error!');
                });

        },


        fileType: function (document) {
            return document.path ? `.${document.path.split('.').pop()}` : '';
        },


        previewable: function(document) {
            return ['.docx', '.pdf', '.doc', '.xls' , '.xlsx'].includes(this.fileType(document))
        },


        columnClass: function (column) {
            return {
                'is-order-asc':  this.sort.key === column && !this.sort.reverse,
                'is-order-desc': this.sort.key === column && this.sort.reverse
            };
        },


        sortBy(key) {
            let params = '';

            if (this.sort.key === key) {
                this.sort.reverse = !this.sort.reverse;

            } else {
                this.sort = { key: key, reverse: false };
            }

            params  = '_order[' + this.sort.key + ']=';
            params += this.sort.reverse ? 'desc' : 'asc';

            this.apiParams.sorting = params;
            this.getDocuments();
        },


        updateFilterParams: function (filters) {
            this.apiParams.filter = buildURLQuery([
                ...filters._search.value ? [`_search=${filters._search.value}`] : [],
                ...filters['startAt[after]'] ? [`createdAt[after]=${filters['startAt[after]']}`] : [],
                ...filters['startAt[before]'] ? [`createdAt[before]=${filters['startAt[before]']}`] : [],
                ...filters['type'].length > 0 ? filters['type'].map(type => `type[]=${type}`) : []
            ]).substring(1);
            this.getDocuments();
        },


        getDocuments: function () {
            if (this.apiParams.filter || this.apiParams.sorting || this.params) {
                this.apiParams.calculated = '?';

                // Filtering
                this.apiParams.calculated += this.apiParams.filter;

                // Sorting
                if (this.apiParams.sorting) {
                    if (this.apiParams.calculated !== '?') {
                        this.apiParams.calculated += '&';
                    }

                    this.apiParams.calculated += this.apiParams.sorting;
                }

                // Params
                    // Multiple
                if (this.params.length > 1) {
                    let partialApiParams = this.apiParams.calculated + (this.apiParams.calculated === '?' ? '' : '&');

                    this.apiParams.calculated = [];
                    this.params.forEach(param => {
                        this.apiParams.calculated.push(partialApiParams + param);
                    });

                    // Single
                } else {
                    // Params given by parent
                    if (this.params && this.params.toString() !== '') {
                        if (this.apiParams.calculated !== '?') {
                            this.apiParams.calculated += '&';
                        }
                        // toString - to be sure we have a string(if one element Array or just a string)
                        this.apiParams.calculated += this.params.toString();
                    }
                }

            } else {
                this.apiParams.calculated = '';
            }

            this.apiCalls();
        },


        previewDocument: function(document) {
            this.previewURL = document;
        },


        editDocument: function (document) {
            this.$refs.modal.openModal(document);
        },


        deleteDocument: function (id) {
            if (confirm('Bist du sicher, dass du dieses Dokument löschen möchtest?')) {
                this.isLoading = true;

                deleteDocument(id).then(response => {
                        let index = this.documents.findIndex(x => x.id === id);
                        this.documents.splice(index, 1);

                        this.isLoading = false;

                        notifySuccess('Das Dokument wurde gelöscht!');

                    }, error => {
                        this.isLoading = false;
                        notifyError('Das Dokument konnte nicht gelöscht werden! Server Error!');
                    });
            }
        },


        apiCalls: function () {
            this.isLoading = true;

            if (this.params.length > 1) {
                // Multiple calls - we need multiple params from the parent
                Promise.all(this.apiParams.calculated.map(param => {
                        return getDocuments(param, {
                            ...this.filterObject && {params: this.filterObject},
                        });
                    })).then(responses => {
                        this.documents = [].concat(...(responses.map(x => x.data)));
                        this.isLoading = false;

                    }, error => {
                        this.isLoading = false;
                        notifyError('Couldn\'t get the Documents! Server Error!');
                    });

            } else {
                // One call - we didn't get more than 1 param from the parent
                getDocuments(this.apiParams.calculated, {
                    ...this.filterObject && {params: this.filterObject},
                }).then(response => {
                        this.documents = response.data;
                        this.isLoading = false;

                    }, error => {
                        this.isLoading = false;
                        notifyError('Couldn\'t get the Documents! Server Error!');
                    });
            }
        },


        // Dropzone
        template: function () {
            return `<div class="dz-preview dz-file-preview">
                        <div class="dz-details">
                            <span data-dz-name></span>
                            <div class="dz-error-message">
                                <span data-dz-errormessage></span>
                            </div>
                        </div>
                        <div class="dz-progress">
                            <span class="dz-upload" data-dz-uploadprogress></span>
                        </div>

                        <div class="dz-remove">
                            <i class="material-icons" data-dz-remove>delete</i>
                        </div>
                    </div>`;
        },

        sendingEvent: function (file, xhr, formData) {
            formData.append('name', file.name.split('.').slice(0, -1).join('.'));
            if (!!this.uploadData && typeof this.uploadData === 'object') {
                Object.keys(this.uploadData).forEach(key => {
                    if (!!this.uploadData[key]) {
                        if (key === 'links') {
                            this.uploadData.links.forEach(link => {
                                formData.append('links[]', JSON.stringify(link))
                            })
                        } else {
                            formData.append(key, this.uploadData[key])
                        }
                    }
                })
            }
        },

        fileSuccess: function (file, response) {
            this.documents = [...this.documents, response];
            this.$refs.dropzone.removeFile(file);
            this.editFile = {
                ...response,
                id: [...this.editFile.id, response.id]
            }
        },

        fileCompleted: function (file) {
            this.queueSize--;

            if (this.queueSize === 0) {
                this.openModal(this.editFile);
            }
        },

        filesAdded: function (files) {
            this.editFile = { id: [] };
            this.queueSize = files.length;
        },

        downloadDocument: function(document) {
            if(document.cloudStorage){
                return document.path;
            }
            return getFilePath(document.id)
        },

        deleteDocumentToMerge: function(document) {
            this.documentsToMerge = this.documentsToMerge.filter(x => x.id !== document.id);
        },



        async createPDF() {

            const mergedPdf = await PDFDocument.create();

            for (const pdfFile of this.documentsToMerge) {
                const arrayBuffer = pdfFile.pdfData;
                const pdfDoc = await PDFDocument.load(arrayBuffer);
                const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());

                copiedPages.forEach((page) => mergedPdf.addPage(page));
            }

            const mergedPdfBytes = await mergedPdf.save();
            const pdfBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });

            // Create a File object
            const pdfFile = new File([pdfBlob], '[merged] ' + this.documentsToMerge[0].name + '.pdf', { type: "application/pdf" });

            // Ensure Dropzone is available
            if (this.$refs.dropzone) {
                this.$refs.dropzone.addFile(pdfFile); // Add the file to Dropzone queue
                this.$refs.dropzone.processQueue();  // Start upload
            } else {
                console.error("Dropzone reference not found");
            }
            //saveAs(new Blob([mergedPdfBytes], { type: "application/pdf" }), "merged.pdf");
        },

        includes,
        getValueWithKey,
        getFilePath
    },


    created: function () {
        optionsDocuments()
            .then(response => {
                this.optionsDocuments = response.data;
                this.$emit('options:loaded', response.data)

            }, error => { notifyError('Couldn\'t load Options! Server Error!'); });

        this.updateFilterParams(this.filters)
    },


    watch: {
        filters: {
            deep: true,
            handler: function (filters) {
                this.updateFilterParams(filters)
            }
        }
    },


    filters: {
        fullYearWithTime
    },


    components: {
        PdfViewer,
        Draggable,
        FormDocument,
        Loading,
        Filters,
        Preview,
        NewEmail,
        UpdatePopover,
        vueDropzone: vue2Dropzone
    }
}
</script>
