<template>
    <vue-dropzone
        ref="dropzone"
        id="dropzone"
        class="dropzone--images-tab"
        v-bind:options="dropzoneOptions"
        useCustomSlot
        v-on:vdropzone-sending="sendingEvent"
        v-on:vdropzone-success="fileSuccess"
        v-on:vdropzone-complete="fileCompleted"
        v-on:vdropzone-files-added="filesAdded"
    >
        <div class="columns">

            <div class="column is-one-fifth">
                <folders v-model="selectedFolder" />
            </div>

            <gallery
                :folder-images="folderImages"
                :enable-google-maps="true"
                :enable-zoom="true"
                column-classes="is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen"
                v-on:openForm="openForm"
                v-on:deleteImage="deleteImage"
                v-on:zoom="zoom"
                :multi-images="true"
            />

            <div class="dz-area">
                <span>Zum Hochladen Bild hier hineinziehen</span>
            </div>

            <image-form ref="form" v-on:submit="handleImageSubmit"/>
        </div>
        <vue-gallery v-bind:images="gallery" v-bind:index="zoomIndex" v-on:close="zoomIndex = null" />





    </vue-dropzone>
</template>

<script>
import vue2Dropzone  from 'vue2-dropzone';
import VueGallery from 'vue-gallery';
import has from 'lodash/has';

import { store } from '@components/CRUD/Item/store';

import Loading from '@components/Loading';
import ColumnItem from '@components/CRUD/Item/ColumnItem';
import ImageForm from './Form';
import Folders from './Folders';

import { authHeader, prepareForBackend } from '@utilities/functions';
import { getImages, optionsImages, deleteImage, editImage, addGoogleImage } from '@api';
import {omit} from "lodash";
import Gallery from "./Gallery";

export default {
    store,

    data: function () {
        return {
            zoomIndex: null,
            images: [],
            isLoading: false,

            dropzoneOptions: {
                url: '/api/images/upload',
                maxFilesize: 20,
                headers: authHeader(),
                previewTemplate: this.template(),
                createImageThumbnails: false,
                clickable: '.bp-panel.is-add',
                paramName: "user_file_upload"
            },

            queueSize: 0,
            editFile: {
                id: []
            },

            selectedFolder: null
        }
    },

    mounted: function () {
        this.fetchImages();
        this.fetchOptions();

        let lastTarget = null;

        window.addEventListener("dragenter", (e) => {
            lastTarget = e.target; // cache the last target here
            document.getElementById("dropzone").classList.add("dz-drag-hover")
        });

        window.addEventListener("dragleave", (e) => {
            if(e.target === lastTarget || e.target === document) {
                document.getElementById("dropzone").classList.remove("dz-drag-hover")
            }
        });
    },

    computed: {
        id: function () { return this.$store.state.id },
        api: function () { return this.$store.state.api },
        options: function () { return this.$store.state.options.images },
        gallery: function () { return this.folderImages.map(image => image.publicPath )},
        folderImages: function() {
            if (!this.selectedFolder) {
                return this.images.filter(image => !image.folder)
            } else {
                return this.images.filter(image => image.folder && image.folder === `/api/image_folders/${this.selectedFolder.id}`)
            }
        },

        paramKey: function () {
            return {
                'airlines': 'provider',
                'others': 'provider',
                'ferries': 'provider',
                'hotels': 'provider',
                'trains': 'provider',
                'destinations': 'destination',
                'order_concepts': 'orderConcept',
            }[this.api]
        },

        params: function () {
            return {
                [`${this.paramKey}.id`]: this.id,
            }
        },

        googleImage: function () {
            const maps = this.images
                .filter(image => image.googleMap);

            if (maps.length > 0) {
                return maps[0]
            } else {
                return null
            }
        }
    },

    methods: {
        editImage: function (data) {
            this.openForm({
                ...data,
                googleMap: {
                    location: '',
                    latitude: null,
                    longitude: null,
                    centerLongitude: null,
                    centerLatitude: null,
                    zoom: 15,
                    ...data.googleMap
                }
            })
        },

        zoom: function(index) {
            this.zoomIndex = index;
        },

        fetchImages: function () {
            this.isLoading = true;

            getImages(this.params)
                .then(response => {
                    this.images = response.data;
                    this.isLoading = false;
                })
        },

        fetchOptions: function () {
            optionsImages()
                .then(response => {
                    this.$store.commit('updateOptions', {
                        images: response.data
                    })
                })
        },

        label: function (image) {
            const type = !!this.options
                && this.options.categories.find(category => category.key === image.type);
            return !!type ? type.value : ''
        },

        deleteImage: function ({id}) {
            if (confirm('Really want to delete this image?')) {
                deleteImage(id)
                    .then(() => {
                        this.images = this.images
                            .filter(image => image.id !== id)
                    })
            }
        },

        openForm: function (data) {
            this.$refs.form.open(data);
        },

        handleImageSubmit: function (data) {
            if (has(data, 'id')) {
                this.handleImageEdit(data)
            } else {
                this.handleImageAdd(data);
            }
        },

        handleImageAdd: function (data) {
            addGoogleImage({
                ...prepareForBackend(data),
                [this.paramKey]: `/api/${this.api}/${this.id}`,
                //path: 'maps.google.com'
            }).then(response => {
                this.images = [...this.images, response.data];
                this.$refs.form.close()
            })
        },

        handleImageEdit: function (data) {
            if (Array.isArray(data.id)) {
                Promise.all(data.id.map(id => editImage({id, data: {
                    ...omit(data, 'id'),
                    type: data.type
                }})))
                .then(responses => {
                    const newImages = responses
                        .map(response => response.data);

                    this.images = [
                        ...this.images.filter(image => {
                            return !newImages.some(newImage => newImage.id === image.id)
                        }),
                        ...newImages
                    ];

                    this.$refs.form.close();
                })
            } else {
                editImage({id: data.id, data: {
                    ...prepareForBackend(data),
                    ...!data.path && { path: '' }
                }})
                    .then(response => {
                        this.images = this.images.map(image => {
                            if (image.id === data.id) {
                                return response.data
                            }

                            return image;
                        });

                        this.$refs.form.close();
                    })
            }
        },

        // Dropzone
        template: function () {
            return `<div class="dz-preview dz-file-preview">
                        <div class="dz-details">
                            <span data-dz-name></span>
                            <div class="dz-error-message">
                                <span data-dz-errormessage></span>
                            </div>
                        </div>
                        <div class="dz-progress">
                            <span class="dz-upload" data-dz-uploadprogress></span>
                        </div>

                        <div class="dz-remove">
                            <i class="material-icons" data-dz-remove>delete</i>
                        </div>
                    </div>`;
        },

        sendingEvent: function (file, xhr, formData) {
            formData.append('title', file.name.split('.').slice(0, -1).join('.'));
            formData.append(this.paramKey, `/api/${this.api}/${this.id}`)
        },

        fileSuccess: function (file, response) {
            this.images = [...this.images, response];
            this.$refs.dropzone.removeFile(file);
            this.editFile = {
                ...response,
                id: [...this.editFile.id, response.id]
            }
        },

        fileCompleted: function (file) {
            this.queueSize--;

            if (this.queueSize === 0) {
                this.openForm(this.editFile);
            }
        },

        filesAdded: function (files) {
            this.editFile = { id: [] };
            this.queueSize = files.length;
        },
    },

    components: {
        Gallery,
        Loading,
        ColumnItem,
        vueDropzone: vue2Dropzone,
        VueGallery,
        ImageForm,
        Folders
    }
}
</script>
