<template>
    <modal-form
        editTitle="Kontakt bearbeiten"
        createTitle="Neuen Kontakt hinzufügen"
    >
        <table>
            <tr>
                <th><label for="role" class="label">Position:</label></th>
                <td>
                    <div class="control">
                        <multiselect
                            v-model="role"
                            :options="options && options.role ? options.role : []"
                            label="value"
                            track-by="key"
                            class="is-shorter"
                            :taggable="true"
                            @tag="testMethod"
                        />

                    </div>
                </td>
            </tr>

            <tr v-if="isTourGuideRole">
                <th><label for="place" class="label">Wohnort:</label></th>
                <td>
                    <div class="control">
                        <async-select
                            api     = "places"
                            v-model = "form.place"
                            crudLinkTitle = "Neuen Ort hinzufügen"
                        />
                    </div>
                </td>
            </tr>

            <template v-if="!isGeneralRole">
                <tr>
                    <th><label for="title" class="label">Titel:</label></th>
                    <td>
                        <div class="control">
                            <multiselect
                                v-model="title"
                                :options="options && options.title ? options.title : []"
                                label="value"
                                track-by="key"
                                class="is-shorter"
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <th><label for="firstName" class="label">Vorname:</label></th>
                    <td>
                        <div class="control">
                            <input type="text" id="firstName" class="input" v-model="form.firstName">
                        </div>
                    </td>
                </tr>
                <tr>
                    <th><label for="lastName" class="label">Nachname:</label></th>
                    <td>
                        <div class="control">
                            <input type="text" id="lastName" class="input" v-model="form.lastName">
                        </div>
                    </td>
                </tr>
            </template>
            <tr>
                <th><label for="email" class="label">Email:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="email" class="input" v-model="form.email">
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="phoneFixed" class="label">Telefon:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="phoneFixed" class="input" v-model="form.phoneFixed">
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="phoneMobile" class="label">Mobil:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="phoneMobile" class="input" v-model="form.phoneMobile">
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="fax" class="label">Fax:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="fax" class="input" v-model="form.fax">
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="languages" class="label">Sprache:</label></th>
                <td>
                    <div class="control">
                        <multiselect
                            v-if="languagesList"
                            v-model="languages"
                            track-by="label"
                            label="name"
                            v-bind:options="languagesList"
                            multiple
                        >
                            <span slot="caret"></span>
                        </multiselect>
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="newsletter" class="label">Newsletter:</label></th>
                <td>
                    <toggle-switch v-model="form.newsletter">
                        <option :value="true">Ja</option>
                        <option :value="false">Nein</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr>
                <th><label for="" class="label">Primärkontakt:</label></th>
                <td>
                   <div class="control">
                       <label class="radio">
                            <input type="radio" value="primary" v-model="form.type">ja
                       </label>
                       <label class="radio">
                            <input type="radio" value="0" v-model="form.type">nein
                       </label>
                    </div>
                </td>
            </tr>

            <tr v-if="isGeneralRole">
                <th><label for="address" class="label">Adresse:</label></th>
                <td>
                    <div class="control">
                        <multiselect
                            v-if="addresses"
                            v-model="form.address"
                            track-by="id"
                            v-bind:custom-label="addressLabel"
                            v-bind:options="addresses"
                        >
                            <span slot="caret"></span>
                        </multiselect>
                    </div>
                </td>
            </tr>
        </table>
    </modal-form>
</template>

<script>
import TabForm from '../form'
import { ToggleSwitch } from '@components/form';

import includes from 'lodash/includes';
import { countries, languages } from '@utilities/variables';

export default {
    mixins: [TabForm],

    property: 'contacts',

    form: {
        address: null,
        email: '',
        fax: '',
        firstName: '',
        languages: [],
        lastName: '',
        phoneFixed: '',
        phoneMobile: '',
        place: null,
        role: '',
        title: '',
        newsletter: false,
        type: null,
    },

    data: function () {
        return {
            languagesList: languages,
            countries
        }
    },

    computed: {
        addresses: function () {
            return this.$store.state.data.addresses;
        },

        title: {
            get () {
                if(this.options && this.options.title) {
                    return this.options.title.find(contact => contact.key === this.form.title)
                }
                return null;
            },
            set (title) {
                this.form.title = title.key;
            }
        },

        role: {
            get () {
                if(this.options && this.options.role) {
                    let role = this.options.role.find(contact => contact.key === this.form.role);
                    return role ? role : {key: this.form.role, value: this.form.role};
                }
                return null;
            },
            set (title) {
                this.form.role = title.key;
            }
        },

        isGeneralRole: function () {
            return this.form.role === 'general_contact';
        },

        isTourGuideRole: function () {
            return this.form.role === 'tour_guide';
        },

        languages: {
            get: function () {
                return this.languagesList.filter(language => includes(this.form.languages, language.label));
            },

            set: function (v) {
                this.form.languages = v.map(language => language.label);
            }
        },
    },

    methods: {
        testMethod (test) {
            this.form.role = test;
        },

        addressLabel: function (address) {
            return `${address.streetAddress}, ${address.city}, ${this.countryName(address.country)}`;
        },

        countryName: function (item) {
            const country = countries && countries.find(c => c.label === item);
            return country && country.name;
        }
    },
    components: {
        ToggleSwitch
    }
}
</script>
