<template>
    <draggable
        v-model="internalValue"
        v-bind:options="options"
        ghost-class="drag-ghost"
        :filter="'.no-drag'"

        @start="controlMsgReader(true)"
        @end="controlMsgReader(false)"
    >
        <slot></slot>
    </draggable>
</template>

<script>

import Draggable from 'vuedraggable';
import {store} from '@orders/store';

export default {
    store: store,
    components: {Draggable},
    props: {
        value: {
            type: Array,
            required: true,
        },
        options: {
            type: Object,
            required: false,
            default: () => {}
        }
    },

    computed: {
        internalValue: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('input', value);
            }
        }
    },

    methods: {
        controlMsgReader(value){
            this.$store.commit('updateDisableMsgReader', value);
        }
    },

}
</script>
