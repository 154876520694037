var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading || !_vm.dataObjects[_vm.formKey]
        ? _c("loading")
        : _vm._l(_vm.dataObjects[_vm.formKey].rows, function (row) {
            return _c(
              "div",
              { staticClass: "columns" },
              _vm._l(row, function (col) {
                return _c(
                  "div",
                  {
                    staticClass: "column pt-2 pb-2 is-align-self-center",
                    class: col.class || [],
                  },
                  [
                    col.component === "label"
                      ? _c(
                          "label",
                          {
                            staticClass: "label",
                            attrs: { for: col.props.for },
                          },
                          [
                            _vm._v(
                              _vm._s(col.value) +
                                _vm._s(col.required ? " *" : "")
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    col.component === "linkLabel" &&
                    _vm.get(_vm.dataObjects[_vm.formKey].form, col.props.for)
                      ? _c("label", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(col.value) + _vm._s(col.required ? " *" : "")
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    col.component === "hr"
                      ? _c("div", {
                          staticStyle: { "border-top": "1px #ccc solid" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    col.component === "link" &&
                    _vm.get(_vm.dataObjects[_vm.formKey].form, col.value)
                      ? _c("div", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.get(
                                  _vm.dataObjects[_vm.formKey].form,
                                  col.value
                                ),
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.get(
                                    _vm.dataObjects[_vm.formKey].form,
                                    col.value
                                  )
                                )
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    col.component === "input" ||
                    _vm.componentExists(col.component)
                      ? [
                          _c("div", { staticClass: "field" }, [
                            _c(
                              "div",
                              { staticClass: "control is-full-width" },
                              [
                                _c(
                                  "error-wrapper",
                                  { attrs: { message: _vm.errors[col.value] } },
                                  [
                                    col.component === "input"
                                      ? _c("input", {
                                          staticClass: "input",
                                          attrs: {
                                            type: "text",
                                            id: col.value,
                                          },
                                          domProps: {
                                            value: _vm.get(
                                              _vm.dataObjects[_vm.formKey].form,
                                              col.value
                                            ),
                                          },
                                          on: {
                                            focus: function ($event) {
                                              return _vm.$emit(
                                                "focus",
                                                col.value
                                              )
                                            },
                                            input: (input) =>
                                              _vm.setValue(
                                                col,
                                                input.target.value
                                              ),
                                          },
                                        })
                                      : _c(
                                          col.component,
                                          _vm._b(
                                            {
                                              tag: "component",
                                              attrs: {
                                                value: _vm.getValue(
                                                  col,
                                                  _vm.get(
                                                    _vm.dataObjects[_vm.formKey]
                                                      .form,
                                                    col.value
                                                  )
                                                ),
                                              },
                                              on: {
                                                input: (input) =>
                                                  _vm.setValue(col, input),
                                                create: function ($event) {},
                                              },
                                            },
                                            "component",
                                            col.props,
                                            false
                                          )
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }