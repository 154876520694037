var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        _vm.dropzone ? "vue-dropzone" : "div",
        {
          ref: "dropzone",
          tag: "component",
          attrs: {
            id: "dropzone",
            options: _vm.dropzoneOptions,
            useCustomSlot: "",
          },
          on: {
            "vdropzone-sending": _vm.sendingEvent,
            "vdropzone-success": _vm.fileSuccess,
            "vdropzone-complete": _vm.fileCompleted,
            "vdropzone-files-added": _vm.filesAdded,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "redesign-table",
              style: _vm.hasBorder ? "" : "border-top:0;",
            },
            [
              _c(
                "div",
                { staticClass: "table-wrapper" },
                [
                  _vm.hasTitle
                    ? _c("div", { staticClass: "row is-header" }, [
                        _c("h2", [_vm._v(_vm._s(_vm.title))]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasFilter
                    ? _c("filters", {
                        staticClass: "row is-filters",
                        attrs: { options: _vm.optionsDocuments },
                        model: {
                          value: _vm.filters,
                          callback: function ($$v) {
                            _vm.filters = $$v
                          },
                          expression: "filters",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasHead
                    ? _c("div", { staticClass: "row is-head" }, [
                        _vm.includes(_vm.columns, "name")
                          ? _c(
                              "div",
                              {
                                staticClass: "cell is-one-third is-sortable",
                                class: _vm.columnClass("name"),
                                on: {
                                  click: function ($event) {
                                    return _vm.sortBy("name")
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-file",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(
                                  "\n                        Dokumentenname\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.includes(_vm.columns, "filetype")
                          ? _c("div", { staticClass: "cell is-smaller-cell" }, [
                              _c("i", {
                                staticClass: "fa fa-file-o",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n                        Dateityp\n                    "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.includes(_vm.columns, "offerId")
                          ? _c(
                              "div",
                              {
                                staticClass: "cell is-sortable",
                                class: _vm.columnClass("description"),
                                on: {
                                  click: function ($event) {
                                    return _vm.sortBy("description")
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-hashtag",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(
                                  "\n                        OfferID\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.includes(_vm.columns, "description")
                          ? _c(
                              "div",
                              {
                                staticClass: "cell is-sortable",
                                class: _vm.columnClass("description"),
                                on: {
                                  click: function ($event) {
                                    return _vm.sortBy("description")
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-file-text",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(
                                  "\n                        Details\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.includes(_vm.columns, "type")
                          ? _c(
                              "div",
                              {
                                staticClass: "cell is-sortable is-larger-cell",
                                class: _vm.columnClass("type"),
                                on: {
                                  click: function ($event) {
                                    return _vm.sortBy("type")
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("help"),
                                ]),
                                _vm._v(
                                  "\n                        Kategorie\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.includes(_vm.columns, "reference")
                          ? _c("div", { staticClass: "cell" }, [
                              _c("i", {
                                staticClass: "fa fa-link",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n                        Bezug\n                    "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.includes(_vm.columns, "source")
                          ? _c(
                              "div",
                              {
                                staticClass: "cell is-sortable",
                                class: _vm.columnClass("source"),
                                on: {
                                  click: function ($event) {
                                    return _vm.sortBy("source")
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("local_library"),
                                ]),
                                _vm._v(
                                  "\n                        Quelle\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.includes(_vm.columns, "createdAt")
                          ? _c(
                              "div",
                              {
                                staticClass: "cell is-sortable is-days-cell",
                                class: _vm.columnClass("createdAt"),
                                on: {
                                  click: function ($event) {
                                    return _vm.sortBy("createdAt")
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("date_range"),
                                ]),
                                _vm._v(
                                  "\n                        Erstellungsdatum\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.includes(_vm.columns, "updatedAt")
                          ? _c(
                              "div",
                              {
                                staticClass: "cell is-sortable",
                                class: _vm.columnClass("updatedAt"),
                                on: {
                                  click: function ($event) {
                                    return _vm.sortBy("updatedAt")
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("date_range"),
                                ]),
                                _vm._v(
                                  "\n                        Änderungsdatum\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "cell is-250px-cell" }, [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("assignment_returned"),
                          ]),
                          _vm._v(
                            "\n                        Verfügbare Aktionen\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "cell is-actions" }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isLoading
                    ? _c("loading")
                    : [
                        _vm.documents.length > 0
                          ? [
                              _c(
                                "div",
                                { staticClass: "is-zebra" },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      attrs: {
                                        value: _vm.documents,
                                        options: {
                                          draggable: ".document-row",
                                          group: "document-group",
                                        },
                                      },
                                    },
                                    _vm._l(_vm.documents, function (document) {
                                      return _c(
                                        "div",
                                        {
                                          key: document.id,
                                          staticClass: "row document-row",
                                          class:
                                            _vm.fileType(document) === ".pdf"
                                              ? ""
                                              : "no-drag",
                                        },
                                        [
                                          _vm.includes(_vm.columns, "name")
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-one-third",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(document.name) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.includes(_vm.columns, "filetype")
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-smaller-cell hide-on-drag",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\n\n                                        " +
                                                      _vm._s(
                                                        _vm.fileType(document)
                                                      ) +
                                                      "\n\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.includes(_vm.columns, "offerId")
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell hide-on-drag",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        document.description
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.includes(
                                            _vm.columns,
                                            "description"
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell hide-on-drag",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        document.description
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.includes(_vm.columns, "type")
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-larger-cell hide-on-drag",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tags" },
                                                    [
                                                      document.type
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "tag is-white",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.getValueWithKey(
                                                                      {
                                                                        key: document.type,
                                                                        optionList:
                                                                          _vm
                                                                            .optionsDocuments
                                                                            .types,
                                                                      }
                                                                    )
                                                                  ) +
                                                                  "\n                                            " +
                                                                  _vm._s(
                                                                    document.year
                                                                      ? document.year
                                                                      : ""
                                                                  ) +
                                                                  "\n                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.includes(_vm.columns, "reference")
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell hide-on-drag",
                                                },
                                                [
                                                  _vm._l(
                                                    document.links,
                                                    function (link) {
                                                      return [
                                                        link.order
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "bordered-object",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    "# " +
                                                                      link.order
                                                                        .id
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : link.client
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "bordered-object",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    link.client
                                                                      .companyName
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : link.orderConcept
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "bordered-object",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    link
                                                                      .orderConcept
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : link.provider
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "bordered-object",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    link
                                                                      .provider
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : link.area
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "bordered-object",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    link.area
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : link.destination
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "bordered-object",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    link
                                                                      .destination
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : link.place
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "bordered-object",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    link.place
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : link.agency
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "bordered-object",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    link.agency
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : link.organisation
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "bordered-object",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    link
                                                                      .organisation
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : link.bank
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "bordered-object",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    link.bank
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.includes(_vm.columns, "source")
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell hide-on-drag",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(document.source) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.includes(_vm.columns, "createdAt")
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-days-cell hide-on-drag",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "fullYearWithTime"
                                                        )(document.createdAt)
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.includes(_vm.columns, "updatedAt")
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell hide-on-drag",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "fullYearWithTime"
                                                        )(document.updatedAt)
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "cell is-250px-cell hide-on-drag",
                                            },
                                            [
                                              document.cloudStorage &&
                                              [".odt", ".docx"].includes(
                                                _vm.fileType(document)
                                              )
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "tag button is-white",
                                                      attrs: {
                                                        title: "Word öffnen",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openWord(
                                                            document.path
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-file-word-o has-margin-right",
                                                      }),
                                                      _vm._v(" öffnen"),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              document.cloudStorage &&
                                              [
                                                ".xlsx",
                                                ".xlsm",
                                                ".xls",
                                              ].includes(_vm.fileType(document))
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "tag button is-white",
                                                      attrs: {
                                                        title: "Excel öffnen",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openExcel(
                                                            document.path
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-file-excel-o has-margin-right",
                                                      }),
                                                      _vm._v(" öffnen"),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.fileType(document) ===
                                                ".docx" ||
                                              (_vm.fileType(document) ===
                                                ".xlsx" &&
                                                document.cloudStorage)
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "tag button is-white",
                                                      attrs: {
                                                        title:
                                                          "in PDF umwandeln",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.convertDocument(
                                                            document.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-file-pdf-o has-margin-right",
                                                      }),
                                                      _vm._v(" umwandeln"),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.fileType(document) === ".pdf"
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "tag button is-white",
                                                      class: {
                                                        "is-loading":
                                                          _vm.buttonIsLoading,
                                                      },
                                                      attrs: {
                                                        title: "Email senden",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.newEmail(
                                                            document
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-file-pdf-o has-margin-right",
                                                      }),
                                                      _vm._v(
                                                        " per Email versenden"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "cell is-actions hide-on-drag",
                                            },
                                            [
                                              _vm.previewable(document)
                                                ? _c(
                                                    "button",
                                                    {
                                                      attrs: {
                                                        title: "Vorschau",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.previewDocument(
                                                            document
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "material-icons",
                                                        },
                                                        [_vm._v("search")]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "update-popover",
                                                {
                                                  attrs: {
                                                    update: {
                                                      updatedAt:
                                                        document.updatedAt,
                                                      updatedBy:
                                                        document.updatedBy,
                                                    },
                                                    api:
                                                      "/api/documents/" +
                                                      document.id,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn-download",
                                                      attrs: {
                                                        title:
                                                          "Letzte Aktualisierung",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "material-icons",
                                                        },
                                                        [_vm._v("update")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "btn-download",
                                                  attrs: {
                                                    title: "Download",
                                                    href: _vm.downloadDocument(
                                                      document
                                                    ),
                                                    target: "_blank",
                                                    download: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons",
                                                    },
                                                    [_vm._v("file_download")]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn-edit",
                                                  attrs: {
                                                    title: "Bearbeiten",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editDocument(
                                                        document
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons",
                                                    },
                                                    [_vm._v("edit")]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn-delete",
                                                  attrs: { title: "Löschen" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteDocument(
                                                        document.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons",
                                                    },
                                                    [_vm._v("delete")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "cell has-text-centered" },
                                [
                                  _vm._v(
                                    "\n                            Keine Dokumente vorhanden\n                        "
                                  ),
                                ]
                              ),
                            ]),
                      ],
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _vm.dropzone
            ? _c(
                "div",
                {
                  staticClass: "dz-dropzone",
                  attrs: { id: "documentDropZone" },
                },
                [_vm._v("Dateien zum hochladen hierhin ziehen")]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.hasMergePanel
        ? _c("div", { staticClass: "bp-panel mt-5" }, [
            _c(
              "div",
              { staticClass: "panel-content" },
              [
                _c("h1", [_vm._v("PDFs zusammenführen")]),
                _vm._v(
                  "\n            Hier können PDF-Dokumente von hereingezogen, per Drag and Drop sortiert und zu einem PDF-Dokument zusammengeführt werden\n            "
                ),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "draggable",
                  {
                    staticClass: "columns",
                    attrs: {
                      element: "div",
                      options: {
                        draggable: ".column",
                        group: "document-group",
                      },
                    },
                    model: {
                      value: _vm.documentsToMerge,
                      callback: function ($$v) {
                        _vm.documentsToMerge = $$v
                      },
                      expression: "documentsToMerge",
                    },
                  },
                  [
                    _vm._l(_vm.documentsToMerge, function (document, index) {
                      return _c(
                        "div",
                        { key: document.id, staticClass: "column is-narrow" },
                        [
                          _c("PdfViewer", {
                            attrs: { document: document },
                            on: {
                              delete: _vm.deleteDocumentToMerge,
                              loaded: (pdfData) => (document.pdfData = pdfData),
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.documentsToMerge.length === 0
                      ? _c("div", { staticClass: "column" }, [
                          _c("div", { staticClass: "pdf-container is-empty" }, [
                            _vm._v("Dokumente hier hereinziehen"),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button is-primary is-full-width",
                    attrs: { disabled: _vm.documentsToMerge.length === 0 },
                    on: { click: _vm.createPDF },
                  },
                  [_vm._v("PDF erstellen")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasForm
        ? _c("form-document", {
            ref: "modal",
            attrs: { optionsDocuments: _vm.optionsDocuments },
            on: { close: _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.emailForm.isVisible
        ? _c("new-email", {
            attrs: {
              "preselected-order": _vm.preselectedOrder,
              "preselected-request": _vm.preselectedRequest,
              preselectedContact: _vm.preselectedRequest
                ? null
                : _vm.emailForm.contact,
              "select-template": _vm.emailForm.template,
              isModalVisible: _vm.emailForm.isVisible,
              "attach-document": _vm.emailForm.attachDocument,
            },
            on: {
              closeModal: function ($event) {
                _vm.emailForm.isVisible = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("preview", {
        model: {
          value: _vm.previewURL,
          callback: function ($$v) {
            _vm.previewURL = $$v
          },
          expression: "previewURL",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }