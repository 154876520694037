<template>

    <div class="column">
        <draggable v-model="images"  v-bind:options="{ disabled: false }">

            <transition-group name="column" tag="div" class="columns is-multiline">

                <div class="column" :class="columnClasses" :style="columnStyle" v-for="(image, index) in folderImages" v-bind:key="image.id">
                        <column-item
                            v-bind:update="{updatedBy: image.updatedBy, updatedAt: image.updatedAt}"
                            :api="'/api/images/' + image.id"
                            v-bind:label="label(image)"
                            v-on:edit="editImage(image)"
                            v-on:delete="deleteImage(image)"
                            class="is-image"
                            v-bind:style="{'background-image': `url('${image.publicPath}')`, 'background-size': isCoverImage ? 'cover' : 'contain', 'background-position': 'center', padding: '0 0 0px'}"
                        >
                            <ul v-if="warning(image)" style="padding-top: 5px;background-color: rgb(247 249 250 / 75%);">
                                <li class="is-flex"><strong class="has-text-danger has-text-centered is-full-width">{{warning(image)}}</strong></li>
                            </ul>

                            <template v-slot:hover="slotProps">
                                <ul v-if="image.title && !warning(image)" style="padding-top: 5px;background-color: rgb(247 249 250 / 75%);">
                                    <li class="is-flex"><strong class="has-text-centered is-full-width">{{image.title}}</strong></li>
                                </ul>
                            </template>

                            <button v-on:click.prevent.stop="$emit('zoom', index)" slot="actions" v-if="enableZoom">
                                <i class="material-icons">zoom_in</i>Zoom
                            </button>

                        </column-item>

                </div>
                <div class="column" :class="columnClasses" :style="columnStyle" key="add" v-if="multiImages || folderImages.length === 0">
                    <div class="bp-panel is-add" :class="dropzoneId">
                        <div>
                            <i class="material-icons" v-if="!addTitle">add</i>
                            <span v-else>{{addTitle}}</span>
                        </div>
                        <div class="panel-actions" v-if="enableGoogleMaps">
                            <button v-on:click.prevent.stop="addGoogleMapImage">
                                <i class="material-icons">map</i>
                                Google Maps (540 x 250)
                            </button>
                        </div>
                    </div>
                </div>
            </transition-group>
        </draggable>

    </div>




</template>

<script>
import vue2Dropzone  from 'vue2-dropzone';
import VueGallery from 'vue-gallery';
import ColumnItem from '@components/CRUD/Item/ColumnItem';
import Draggable from 'vuedraggable';

import { store } from '@components/CRUD/Item/store';

import {deleteImage, editImage} from '@api';

export default {
    props: {
        columnClasses: {
            type: String,
            default: 'is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen',
        },
        addTitle: {
            type: String,
            default: '',
        },
        columnStyle: {
            type: Object,
            default: () => {},
        },
        folderImages: {
            type: Array,
            required: true,
        },
        enableGoogleMaps: {
            type: Boolean,
            default: false,
        },
        enableZoom: {
            type: Boolean,
            default: false,
        },
        multiImages: {
            type: Boolean,
            default: false
        },
        hasLabel: {
            type: Boolean,
            default: true,
        },
        isCoverImage: {
            type: Boolean,
            default: false,
        },
        dropzoneId: {
            type: String,
            default: 'dropzone-id-0'
        },
        maxFileSize: {
            type: Number,
            default: null
        }
    },


    store,

    data: function () {
        return {
            zoomIndex: null,
        }
    },

    computed: {
        options: function () { return this.$store.state.options.images },

        googleImage: function () {
            const maps = this.folderImages
                .filter(image => image.googleMap);

            if (maps.length > 0) {
                return maps[0]
            } else {
                return null
            }
        },
        images: {
            get: function() {
                return this.folderImages.sort((a,b) => a.position - b.position);
            },
            set: function(images) {
                this.changeOrder(images);
            }
        }
    },

    methods: {
        changeOrder: function(images) {
            images.map((image, index) => {
                image.position = index;
                editImage({id: image.id, data: image})
            })

        },
        warning (image) {
            return this.maxFileSize && image.size > this.maxFileSize ? 'Bild ist zu groß!' : '';
        },

        addGoogleMapImage: function () {
            this.$emit('openForm', {
                isGoogleMaps: true,
                type: 'map',
                ...!!this.googleImage && {
                    googleMap: {
                        longitude: null,
                        latitude: null,
                        centerLongitude: null,
                        centerLatitude: null,
                        zoom: 15,
                        location: this.googleImage.googleMap.location,
                    }
                }
            })
        },

        editImage: function (data) {
            this.$emit('openForm', data);
        },

        label: function (image) {
            if(!this.hasLabel){
                return '';
            }
            const type = !!this.options
                && this.options.categories.find(category => category.key === image.type);
            return !!type ? type.value : ''
        },

        deleteImage: function (item) {
            this.$emit('deleteImage', item);
        },
    },

    components: {
        ColumnItem,
        vueDropzone: vue2Dropzone,
        VueGallery,
        Draggable
    }
}
</script>
