var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      attrs: {
        options: _vm.options,
        "ghost-class": "drag-ghost",
        filter: ".no-drag",
      },
      on: {
        start: function ($event) {
          return _vm.controlMsgReader(true)
        },
        end: function ($event) {
          return _vm.controlMsgReader(false)
        },
      },
      model: {
        value: _vm.internalValue,
        callback: function ($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }