<template>
    <div>
        <modal-form
            editTitle="Hinweis bearbeiten"
            createTitle="Neuen Hinweis hinzufügen"
            v-on:imageUploaded="handleImageDrop"
        >
            <table>
                <template v-if="!editMode">
                    <tr>
                        <th>
                            <label for="type" class="label">Art:</label>
                        </th>
                        <td>
                            <toggle-switch v-model="form.tripDetailType">
                                <option :value="null">nur Hinweis</option>
                                <option v-for="(name, value) in tripDetailTypesFiltered" v-bind:key="value" v-bind:value="value">{{name}}</option>
                            </toggle-switch>
                        </td>
                    </tr>
                </template>

                <tr>
                    <th class="is-top">
                        <label for="images" class="label">Bilder (540 x 250):</label>
                    </th>
                    <td>
                        <image-upload v-model="form.images" multiple label="Bild hochladen">
                            <template slot="buttons">
                                <button class="button" v-on:click.prevent.stop="visibleImageMap = true">
                                    <i class="material-icons">map</i>
                                    Google Map Karte
                                </button>
                                <button class="button" v-on:click.prevent.stop="$refs.imageSelect.open()">
                                    <i class="material-icons">photo</i>
                                    Bild aus Stammdaten
                                </button>
                            </template>
                        </image-upload>
                    </td>
                </tr>

                <tr v-if="isNotDestination">
                    <th>
                        <label for="sections" class="label">Anzeigen:</label>
                    </th>
                    <td>
                        <toggle-switch v-model="form.sections">
                            <option v-for="(name, value) in infoSections" v-bind:key="value" v-bind:value="value">{{name}}</option>
                        </toggle-switch>
                    </td>
                </tr>

                <tr>
                    <th><label for="importance" class="label">Wichtigkeit:</label></th>
                    <td>
                        <multiselect
                            v-bind:options="importances"
                            v-model="importance"
                            track-by="key"
                            label="value"
                            class="is-medium"
                        />
                    </td>
                </tr>


                <tr v-if="!item.providerType && isNotDestination">
                    <th><label for="form.showInConnectedProviders" class="label">Gültigkeit:</label></th>
                    <td>
                        <toggle-switch v-model="form.showInConnectedProviders">
                            <option :value=false>Nur für Agentur</option>
                            <option :value=true>Auch für verbundene Anbieter</option>
                        </toggle-switch>
                    </td>
                </tr>

                <tr>
                    <td colspan="2">
                        <div v-if="form.tripDetailType === 'provider_info'" class="redesign-table">
                            <div class="row is-head">
                                <div class="cell">Titel</div>
                                <div class="cell">Hinweis</div>
                            </div>
                            <div class="row is-h-auto">
                                <div class="cell is-gapless self-stretch">
                                    <div class="input-wrapper">
                                        <multiselect
                                            ref="title"
                                            v-bind:options="titles"
                                            v-bind:value="form.title"
                                            v-bind:taggable="true"
                                            v-on:tag="addTitle"
                                            v-on:close="closeTitle"
                                            v-on:search-change="title => searchTitle = title"
                                            v-on:open="$refs.title.updateSearch(form.title)"
                                            v-on:input="title => addTitle(title)"
                                        />
                                    </div>
                                </div>
                                <div class="cell is-gapless">
                                    <resizable>
                                        <textarea
                                            rows="1"
                                            class="textarea"
                                            v-model="form.content"
                                        ></textarea>
                                    </resizable>
                                </div>
                            </div>
                        </div>
                        <div v-else class="bp-panel">
                            <div class="panel-content">
                                <div class="field">
                                    <multiselect
                                        ref="title"
                                        v-bind:options="titles"
                                        v-bind:value="form.title"
                                        v-bind:taggable="true"
                                        v-on:tag="addTitle"
                                        v-on:close="closeTitle"
                                        v-on:search-change="title => searchTitle = title"
                                        v-on:open="$refs.title.updateSearch(form.title)"
                                        v-on:input="title => addTitle(title)"
                                    />
                                </div>
                                <div class="field">
                                    <tip-tap
                                        v-model="form.content"
                                        class="is-fullwidth"
                                        isNotAlone
                                        :menuItems="['fullscreen', 'bold', 'italic', 'underline', 'h1', 'h2', 'bullet_list', 'ordered_list', 'link', 'color', 'undo']"
                                    />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <translations-field v-model="locale" :translations="form.translations" :translation-options="translationOptions" v-on:changeTranslation="changeTranslation"></translations-field>

            </table>
        </modal-form>

        <image-select
            ref="imageSelect"
            :params="{'provider.id': providerID}"
            v-model="form.images"
            multiple
        />

        <image-map
            v-if="visibleImageMap"
            v-model="form.images"
            v-on:close="visibleImageMap = false"
        />
    </div>
</template>

<script>
import { ToggleSwitch, ImageUpload, ImageMap, ImageSelect, TipTap, Resizable } from '@components/form';
import { tripDetailTypes } from '@utilities/variables'
import { infoSections } from '@clientSpecific/utilities/variables';

import TabForm from '../form'
import {translationsField, translationsMixin} from "@utilities/mixins";
import {addArea, edit, editArea, getAxios, post} from "@api";
import {prepareForBackend} from "@utilities/functions";

export default {
    mixins: [ TabForm, translationsMixin ],

    form: {
        title: '',
        content: '',
        type: 'note',
        sections: [],
        showInConnectedProviders: false,
        tripDetailType: null,
        importance: 'low',
        images: [],
        translations: [],
    },

    property: 'infos',

    data: function () {
        return {
            infoSections,
            tripDetailTypes,
            searchTitle: '',
            visibleImageMap: false,
        }
    },

    computed: {
        tripDetailTypesFiltered: function() {
            if(this.hasTripDetails){
                return tripDetailTypes;
            } else {
                return {}
            }
        },

        providerID: function() {
            return this.$store.state.id
        },

        options: function () {
            const options = this.$store.state.options;
            return {
                ...options.infos,
            }
        },

        titles: function () {
            return this.options && this.options.infoTitle
                ? this.options.infoTitle
                : []
        },

        isNotDestination: function () {
            return !['destinations', 'clients'].includes(window.location.pathname.split( '/' )[1]);
        },

        hasTripDetails: function() {
            //todo: find better solution
            const api = window.location.pathname.split( '/' )[1];
            return api === 'hotels' || api === 'ferries' || api === 'others' || api === 'trains' ;
        },

        type () {
            return window.location.pathname.split( '/' )[1];
        },

        importances: function () {
            return this.options.importance ? this.options.importance : []
        },

        importance: {
            get: function () {
                return this.importances.find(importance => importance.key === this.form.importance)
            },
            set: function (value) {
                this.form.importance = !!value ? value.key : null
            }
        },
    },

    components: {
        translationsField,
        ToggleSwitch,
        ImageUpload,
        ImageSelect,
        Resizable,
        ImageMap,
        TipTap
    },

    methods: {
        addTitle(newTitle) {
            if (newTitle !== this.form.title) {
                this.form.title = newTitle;
            }
        },

        closeTitle() {
            if (this.searchTitle !== "") {
                this.addTitle(this.searchTitle)
            }
        },

        handleImageDrop: function(image) {
            this.form.images = [...this.form.images, image]
        },

        openCallback() {
            if(this.form.id) {
                getAxios('infos/' + this.form.id, {_groups: ['image_read', 'translations']}).then(response => {
                    this.form = response.data;
                    this.locale = this.defaultTranslation;
                    if(!this.form.translations || Object.keys(this.form.translations).length === 0){
                        this.form.translations = Object.assign({}, {   [this.defaultTranslation] : {}     });
                        //this.form.translations.push({   [this.defaultTranslation] : {}     })
                    }
                })
            } else {
                this.locale = this.defaultTranslation;
                if(!this.form.translations || Object.keys(this.form.translations).length === 0){
                    this.form.translations = Object.assign({}, {   [this.defaultTranslation] : {}     });
                    //this.form.translations.push({   [this.defaultTranslation] : {}     })
                }
            }
        },

        create () {
            let data = (JSON.parse(JSON.stringify(this.form)));
            if(this.type === 'agencies') {
                data.agency = '/api/agencies/' + this.providerID;
            }
            if(this.type === 'clients') {
                data.client = '/api/clients/' + this.providerID;
            }
            if(this.type === 'destinations') {
                data.destination = '/api/destinations/' + this.providerID;
            }
            if(this.type === 'areas') {
                data.destination = '/api/areas/' + this.providerID;
            }
            if(this.type === 'places') {
                data.destination = '/api/places/' + this.providerID;
            }
            if(this.type === 'routes') {
                data.predefinedRouteDetail = '/api/predefined_route_details/' + this.providerID;
            }
            if(this.type === 'others' || this.type === 'trains' || this.type === 'hotels' || this.type === 'ferries' || this.type === 'airlines') {
                data.provider = '/api/providers/' + this.providerID;
            }
            data = prepareForBackend(data);

            return new Promise((resolve, reject) => {
                post('infos', data,
                    {
                        _groups: ['translations', 'image_read', 'info_write', 'info:provider', 'info:client', 'info:location_destination', 'info:location_area', 'info:location_place', 'info:order_route_detail_predefined']}
                ).then(response => {
                    if(data.tripDetailType) {
                        post('provider_trip_details', {
                            ...data,
                            info: 'api/infos/' + response.data.id,
                            type: data.tripDetailType,
                            isOnlyEditableInOrder: false,
                        }, {
                            _groups: []}
                        ).then(res => {
                            response.data.providerTripDetail = res.data;
                            this.$store.state.data.providerTripDetails.push(res.data);
                            resolve(response);
                        })
                    } else {
                        resolve(response);
                    }
                })
            })


        },
        update () {
            let data = prepareForBackend(JSON.parse(JSON.stringify(this.form)));
            let id = this.form.id;

            return edit('infos/' +  id, data, {_groups: ['translations', 'image_read', 'info_write']}, {
                headers: {
                    'X-LOCALE': this.locale
                }
            })
        },
    }
}
</script>
