<template>
    <div class="pdf-container">

        <div v-if="document" class="has-text-centered">
            <h3 class="is-small-text has-text-centered">{{document.name}}</h3>
            <loading class="is-small" textInFront="" text-after="" v-if="isLoading"/>
            <canvas ref="pdfCanvas" v-if="!isLoading"></canvas>
            <button class="btn-delete" title="Löschen" v-on:click="$emit('delete', document)">
                <i class="material-icons">delete</i>
            </button>
        </div>

    </div>
</template>

<script>
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import store from "./store"
import Loading from "@components/Loading.vue";

// ✅ Set correct worker path for Webpack 5
pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
    "/node_modules/pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url
).toString();

export default {
    components: {Loading},
    store: store,
    props: {
        document: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            isLoading: false,
        }
    },
    computed: {
        token () {
            return this.$store.state.microsoftStore.token;
        },
        tokenExpiration () {
            return this.$store.state.microsoftStore.tokenExpiration;
        }
    },
    methods: {
        async fetchAndLoadPdf() {
            this.isLoading = true;
            try {
                const response = await fetch(
                    `https://graph.microsoft.com/v1.0/drives/${this.document.cloudStorage.driveId}/items/${this.document.cloudStorage.itemId}/content`,
                    {
                        method: "GET",
                        headers: {
                            "Authorization": `Bearer ${this.token}`, // 🔑 OAuth token
                            "Accept": "application/pdf",
                        },
                    }
                );

                if (!response.ok) throw new Error(`Failed to fetch PDF: ${response.statusText}`);

                const pdfData = await response.arrayBuffer(); // Convert response to binary
                this.$emit('loaded', pdfData);
                this.isLoading = false;
                this.renderPdf(pdfData); // Pass binary PDF data to renderer
            } catch (error) {
                this.isLoading = false;
                console.error("Error fetching PDF:", error);
            }
        },

        async renderPdf(pdfData) {
            try {
                const loadingTask = pdfjsLib.getDocument({ data: pdfData });
                const pdf = await loadingTask.promise;
                const page = await pdf.getPage(1);

                const scale = 0.2;
                const viewport = page.getViewport({ scale });

                const canvas = this.$refs.pdfCanvas;
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                // ✅ Fix: Use `renderTask.promise.then()` instead of `then()`
                const renderTask = page.render(renderContext);
                await renderTask.promise; // Use `.promise` instead of `.then()`
            } catch (error) {
                console.error("Error rendering PDF:", error);
            }
        },
    },
    mounted() {
        if(this.token && this.tokenExpiration > Date.now() / 1000) {
            this.fetchAndLoadPdf();
        } else {
            this.$store.state.microsoftStore.token = null;
            this.$store.dispatch('getToken');
        }
    },
    watch: {
        token: function () {
            if(this.token) {
                this.fetchAndLoadPdf();
            }
        }
    }
};
</script>


