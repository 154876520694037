var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Kontakt bearbeiten",
        createTitle: "Neuen Kontakt hinzufügen",
      },
    },
    [
      _c(
        "table",
        [
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "role" } }, [
                _vm._v("Position:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("multiselect", {
                    staticClass: "is-shorter",
                    attrs: {
                      options:
                        _vm.options && _vm.options.role ? _vm.options.role : [],
                      label: "value",
                      "track-by": "key",
                      taggable: true,
                    },
                    on: { tag: _vm.testMethod },
                    model: {
                      value: _vm.role,
                      callback: function ($$v) {
                        _vm.role = $$v
                      },
                      expression: "role",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.isTourGuideRole
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "place" } },
                    [_vm._v("Wohnort:")]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("async-select", {
                        attrs: {
                          api: "places",
                          crudLinkTitle: "Neuen Ort hinzufügen",
                        },
                        model: {
                          value: _vm.form.place,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "place", $$v)
                          },
                          expression: "form.place",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isGeneralRole
            ? [
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "title" } },
                      [_vm._v("Titel:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("multiselect", {
                          staticClass: "is-shorter",
                          attrs: {
                            options:
                              _vm.options && _vm.options.title
                                ? _vm.options.title
                                : [],
                            label: "value",
                            "track-by": "key",
                          },
                          model: {
                            value: _vm.title,
                            callback: function ($$v) {
                              _vm.title = $$v
                            },
                            expression: "title",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "firstName" } },
                      [_vm._v("Vorname:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.firstName,
                            expression: "form.firstName",
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text", id: "firstName" },
                        domProps: { value: _vm.form.firstName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "firstName", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "lastName" } },
                      [_vm._v("Nachname:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.lastName,
                            expression: "form.lastName",
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text", id: "lastName" },
                        domProps: { value: _vm.form.lastName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "lastName", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "email" } }, [
                _vm._v("Email:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "email" },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "phoneFixed" } },
                [_vm._v("Telefon:")]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.phoneFixed,
                      expression: "form.phoneFixed",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "phoneFixed" },
                  domProps: { value: _vm.form.phoneFixed },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "phoneFixed", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "phoneMobile" } },
                [_vm._v("Mobil:")]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.phoneMobile,
                      expression: "form.phoneMobile",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "phoneMobile" },
                  domProps: { value: _vm.form.phoneMobile },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "phoneMobile", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "fax" } }, [
                _vm._v("Fax:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.fax,
                      expression: "form.fax",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", id: "fax" },
                  domProps: { value: _vm.form.fax },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "fax", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "languages" } },
                [_vm._v("Sprache:")]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _vm.languagesList
                    ? _c(
                        "multiselect",
                        {
                          attrs: {
                            "track-by": "label",
                            label: "name",
                            options: _vm.languagesList,
                            multiple: "",
                          },
                          model: {
                            value: _vm.languages,
                            callback: function ($$v) {
                              _vm.languages = $$v
                            },
                            expression: "languages",
                          },
                        },
                        [
                          _c("span", {
                            attrs: { slot: "caret" },
                            slot: "caret",
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "newsletter" } },
                [_vm._v("Newsletter:")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "toggle-switch",
                  {
                    model: {
                      value: _vm.form.newsletter,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "newsletter", $$v)
                      },
                      expression: "form.newsletter",
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [_vm._v("Ja")]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v("Nein"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Primärkontakt:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("label", { staticClass: "radio" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.type,
                        expression: "form.type",
                      },
                    ],
                    attrs: { type: "radio", value: "primary" },
                    domProps: { checked: _vm._q(_vm.form.type, "primary") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.form, "type", "primary")
                      },
                    },
                  }),
                  _vm._v("ja\n                   "),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "radio" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.type,
                        expression: "form.type",
                      },
                    ],
                    attrs: { type: "radio", value: "0" },
                    domProps: { checked: _vm._q(_vm.form.type, "0") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.form, "type", "0")
                      },
                    },
                  }),
                  _vm._v("nein\n                   "),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.isGeneralRole
            ? _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "address" } },
                    [_vm._v("Adresse:")]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _vm.addresses
                        ? _c(
                            "multiselect",
                            {
                              attrs: {
                                "track-by": "id",
                                "custom-label": _vm.addressLabel,
                                options: _vm.addresses,
                              },
                              model: {
                                value: _vm.form.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "address", $$v)
                                },
                                expression: "form.address",
                              },
                            },
                            [
                              _c("span", {
                                attrs: { slot: "caret" },
                                slot: "caret",
                              }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }