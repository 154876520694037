<template>
    <modal-form
        editTitle="Preise für Zusatzleistung bearbeiten"
        createTitle="Preise für Zusatzleistung hinzufügen"
        class="is-large"
        :is-loading="isLoading"
    >
        <table>
            <template v-if="initialValue">
                <tr>
                    <th><label class="label">Preis:</label></th>
                    <td>
                        <toggle-switch v-model="priceType">
                            <option value="calculation">Kalkulation</option>
                            <option value="provider">Anbieter</option>
                            <option value="agency">Agentur</option>
                        </toggle-switch>
                    </td>
                </tr>
                <tr v-if="priceType === 'agency'">
                    <th><label class="label">Agentur:</label></th>
                    <td>
                        <async-select
                            api="agencies"
                            v-model="agency"
                        />
                    </td>
                </tr>
                <tr v-if="priceType === 'provider' || priceType === 'agency'">
                    <th><label class="label">Leistungsträger:</label></th>
                    <td>
                        <async-select
                            api="providers"
                            v-model="provider"
                        />
                    </td>
                </tr>
            </template>

            <tr v-if="providerType === 'provider'">
                <th><label class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">Direktpreis</option>
                        <option value="agency">Preis von Agentur</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr v-if="providerType === 'agency'">
                <th><label class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">No-Name Preis</option>
                        <option value="hotel">Preis für einen bestimmten Leistungsträger</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr v-if="type === 'agency'">
                <th><label class="label">Agentur:</label></th>
                <td>
                    <async-select
                        api="agencies"
                        v-model="agency"
                    />
                </td>
            </tr>

            <tr v-if="type === 'hotel'">
                <th><label class="label">Leistungsträger:</label></th>
                <td>
                    <async-select
                        api="others"
                        v-model="provider"
                    />
                </td>
            </tr>

            <tr>
                <th><label class="label">Leistung:</label></th>
                <td>
                    <toggle-switch v-model="existingService">
                        <option :value="true">Bestehende Leistung</option>
                        <option :value="false">Neue Leistung</option>
                    </toggle-switch>
                </td>
            </tr>


                <th><label class="label">Zusatzleistung:</label></th>
                <td>
                    <async-select
                        api="other_service_types"
                        v-model="otherServiceType"
                        :params="{
                            ...this.provider && this.existingService && this.includeProvider && {'otherServices.provider.id': this.provider.id},
                            ...this.agency && this.existingService && this.includeAgency && {'otherServices.agency.id': this.agency.id},
                            ...(!this.includeProvider && this.existingService ) && {
                                    'otherServices.provider[exists]': false,
                                },
                                ...(!this.includeAgency && this.existingService) && {
                                    'otherServices.agency[exists]': false,
                                },
                            _groups: ['service_day_time'],
                        }"
                    />
                </td>

            <!-- Preise -->
            <tr>
                <td colspan="2">
                    <h2>Preise <i class="material-icons" v-on:click="addPrice">add_circle</i></h2>
                </td>
            </tr>

        </table>

        <div>

            <edit-table
                @delete="deletePeriod"
                :filters="filter"
                :rows="rows"
                :load-options="false"
                :footer="false"
                ref="table"
                @updateFilter="value => filter = value"
                :passed-options="options"
                @saveRow="saveRow"
                :columns="columns"
            >
                <template v-slot:days="slotProps">
                    <div>
                        <days :picker=true :is-in-table=true :value="getWeekdays(slotProps.row)" @input="input => setWeekDays(input, slotProps.row)"></days>
                    </div>
                </template>
                <template v-slot:serviceDayTimes="slotProps">
                    <div>
                        <span v-if="slotProps.row.priceGroup.serviceDayTimes.length">{{slotProps.row.priceGroup.serviceDayTimes.length}}</span>
                        <span v-else>alle</span>
                        <button
                            class="btn-edit"
                            v-on:click.prevent.stop="priceToBeEdited = slotProps.row.priceGroup"
                        ><i class="material-icons">edit</i></button>
                    </div>
                </template>
                <template v-slot:linkedGroup="slotProps">
                    <div v-if="slotProps.row.priceGroup.isNew" style="margin-left: 30px">
                        <multiselect :allow-empty="false" :value="slotProps.row.priceGroup.id" @input="input => changePriceGroup(slotProps.row, input)" :options="getNewPriceGroups"/>
                    </div>
                    <div v-else :style="getBackground(slotProps.row)" style="height: 100%; width: 100%; align-content:center;text-align: center">
                        {{slotProps.row.priceGroup.id}}
                    </div>
                </template>


            </edit-table>
            <modal
                class="is-small is-form"
                :visible="!!priceToBeEdited"
            >
                <p slot="header">Preise gültig für folgende Zeiten</p>
                <div slot="content">
                    <service-day-time-field v-if="priceToBeEdited" label="Gültig für" :fields="['startTimeOfDay', 'endTimeOfDay', 'checkInTimeOfDay']" v-model="priceToBeEdited.serviceDayTimes" :predefined-day-times="predefinedDayTimes" />
                </div>
                <div class="level" slot="footer">
                    <button class="button is-primary" v-on:click.prevent.stop="priceToBeEdited=null">
                        Fenster schließen
                    </button>
                </div>
            </modal>
            <pagination v-model="pagination" v-on:input="getPrices" :name="{singluar: 'Preisgruppe', plural: 'Preisgruppen'}" />
        </div>
    </modal-form>
</template>

<script>
import Days from '@components/Days';
import { InputDate, InputPrice, ToggleSwitch, AreaSelect, AsyncSelect } from '@components/form';
import {compareDates, daysToNumbers, numbersToDays} from '@utilities/functions';
import TabForm from '../../form'
import {importCheckedMixin, importCheckedField, serviceDayTimeField} from '@utilities/mixins';
import {
    getAxios,
    axiosByMethod,
    axiosDelete,
    getOtherPlaceholder
} from '@api'
import {notifyError, notifySuccess} from "@components/Notification";
import moment from "moment";
import EditTable from "@components/EditTable/Index.vue";
import set from "lodash/set";
import Pagination from "@components/CRUD/Pagination.vue";
import {omit} from "lodash";
import Modal from '@components/Modal';




export default {
    mixins: [ TabForm, importCheckedMixin ],

    props: {
        providerType: {
            type: String,
            required: true
        }
    },

    components: {
        serviceDayTimeField,
        Modal,
        Pagination,
        EditTable,
        Days,
        InputDate,
        InputPrice,
        ToggleSwitch,
        importCheckedField,
        AreaSelect,
        AsyncSelect,
    },

    form: {
        otherServiceType: null,
        provider: null,
        agency: null,
    },

    data: function () {
        return {
            type: 'direct',
            existingService: true,
            priceType: 'calculation',
            prices: [],
            filter: {
                'endAt[after]': '01.01.' + moment().format('YYYY'),
                'startAt[before]': null,
            },
            pagination: {
                current: 1,
                perPage: 100,
                items: 0
            },
            priceGroupIdsToDelete: [],
            defaultProviderPrices: [
                {
                    priceType: 'buy_price',
                    price: {
                        amount: null,
                        currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                    }
                },
                {
                    priceType: 'sell_price',
                    price: {
                        amount: null,
                        currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                    }
                },
            ],
            priceToBeEdited: null,
        }
    },

    computed: {
        columns () {
            return [
                { key: 'linkedGroup', label:  'Preisgruppe', class: 'has-border is-120px-cell has-text-centered is-editable', formElement: ''},
                { key: 'period.startAt', label: 'Beginn', class: 'has-border has-text-centered is-date-cell', formElement: 'InputDateTable', props: {onlyDate: true} },
                { key: 'period.endAt', label: 'Ende', class: 'has-border has-text-centered is-date-cell', formElement: 'InputDateTable', props: {onlyDate: true} },
                { key: 'buyPrice.price.amount', label:  'EK-Preis', class: 'has-border is-60px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                { key: 'sellPrice.price.amount', label:  'VK-Preis', class: 'has-border is-60px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                { key: 'buyPrice.price.currency', label: 'CUR', class: 'has-border is-editable is-60px-cell has-text-centered', formElement: 'Multiselect', props: {'allow-empty': false, options: this.options.potentialCurrencies }},
                { key: 'priceGroup.otherServiceSubtitle', label: 'Zusatztitel Leistung (optional)', formElement: 'TextInput', class: 'has-border', style: 'flex-grow:1'},
                { key: 'priceGroup.name', label: 'Bezeichnung Zeitraum (optional)', formElement: 'TextInput', class: 'has-border', style: 'flex-grow:1'},
                { key: 'days', label: 'Wochentage', formElement: '', class: 'has-border has-text-centered is-editable', style: 'width: 203px; flex-grow:0'},
                { key: 'serviceDayTimes', label: 'Gültig für Zeiten', formElement: 'TextInput', class: 'has-border is-100px-cell has-text-centered'}
                /*
                todo: Maybe put at top or with icon
                { key: 'checked', label:  'geprüft', class: 'has-border is-editable is-icon-cell',style: 'flex-grow:1', formElement: 'PureText', props: {label: 'value', 'track-by': 'key', options: this.options.boardExtended} },
*/
            ]
        },

        rows: {
            get () {
                let rows = [];
                this.prices.forEach(price => {
                    price.timePeriods.forEach(timePeriod => {
                        rows.push(
                            {
                                key: timePeriod.id,
                                priceGroup: price,
                                period: timePeriod,
                                buyPrice: price.providerPrices.find(price => price.priceType === 'buy_price'),
                                sellPrice: price.providerPrices.find(price => price.priceType === 'sell_price'),
                            }
                        )
                    })
                })
                return rows.sort((a, b) => {
                    if(a.priceGroup.isNew && !b.priceGroup.isNew) {
                        return -1;
                    }
                    if(b.priceGroup.isNew && !a.priceGroup.isNew) {
                        return 1;
                    }
                    if(a.priceGroup.isNew && b.priceGroup.isNew) {
                        return 0;
                    }
                    if(b.priceGroup.otherServiceSubtitle && !a.priceGroup.otherServiceSubtitle) {
                        return -1;
                    }
                    if(a.priceGroup.otherServiceSubtitle && !b.priceGroup.otherServiceSubtitle) {
                        return 1;
                    }
                    if(!a.priceGroup.otherServiceSubtitle && !b.priceGroup.otherServiceSubtitle) {
                        return compareDates(a.period.startAt, b.period.startAt);
                    }
                    if(a.priceGroup.otherServiceSubtitle !== b.priceGroup.otherServiceSubtitle) {
                        return a.priceGroup.otherServiceSubtitle.localeCompare(b.priceGroup.otherServiceSubtitle);
                    }
                })
            },
            set (rows) {
                rows.forEach(row => {
                    let priceGroup = this.prices.find(price => price.id === row.priceGroup.id);
                    this.prices = this.prices.map(price => {
                        if(price.id === priceGroup.id) {
                            return {
                                ...price,
                                ...row.priceGroup,
                                timePeriods: priceGroup.timePeriods.map(tp => {
                                    if(tp.id === row.period.id) {
                                        return {...tp, ...row.period}
                                    } else {
                                        return tp;
                                    }
                                }),
                                providerPrices: [
                                    {...row.buyPrice},
                                    {...row.sellPrice},
                                ]
                            }
                        } else {
                            return price;
                        }
                    })
                })
            }
        },

        agency: {
            get: function () { return this.form.agency && !!this.form.agency.id ? this.form.agency : null },
            set: function (agency) { this.form.agency = agency }
        },

        provider: {
            get: function () { return this.form.provider && !!this.form.provider.id ? this.form.provider : null },
            set: function (provider) { this.form.provider = provider }
        },

        otherServiceType: {
            get: function () { return this.form.otherServiceType && this.form.otherServiceType.id ? this.form.otherServiceType : null },
            set: function (provider) { this.form.otherServiceType = provider }
        },

        includeProvider () {
            return this.providerType === 'provider' || this.priceType === 'provider' || (this.priceType === 'agency' && this.provider) || (this.providerType === 'agency' && this.type === 'hotel');
        },

        includeAgency () {
            return this.providerType === 'agency' || this.priceType === 'agency' || (this.providerType === 'provider' && this.type === 'agency');
        },

        computedFilters () {
            return {
                ...this.filter,
                'timePeriods.endAt[after]': this.filter['endAt[after]'],
                'timePeriods.startAt[before]': this.filter['startAt[before]'],
                _itemsPerPage: this.pagination.perPage,
                _page: this.pagination.current,
            }
        },

        getNewPriceGroups () {
            let newPriceGroups = [];
            this.prices.filter(ele => ele.isNew).forEach(price => {
                price.timePeriods.forEach(period => {
                    newPriceGroups.push(period.id)
                })
            });
            return newPriceGroups;
        },

        predefinedDayTimes: function () {
            return this.otherServiceType && this.otherServiceType.serviceDayTimes ? this.otherServiceType.serviceDayTimes.map(detail => {
                detail.key = detail.id;
                delete detail.id;
                return detail;
            }) : [];
        },
    },

    methods: {
        getWeekdays (row) {
            return daysToNumbers(row.period)
        },

        setWeekDays (input, row) {
            this.rows = this.rows.map(myRow => {
                if(myRow.key === row.key) {
                    row.priceGroup.hasChanged = true;
                    let period = {
                        ...row.period,
                        ...numbersToDays(input)
                    }
                    return set(row, 'period', period)
                } else {
                    return row
                }
            })
            row = set(row, 'period', {
                ...row.period,
                ...numbersToDays(input)
            });

        },


        getPrices() {

            if(!this.otherServiceType) {
                return;
            }

            this.$refs.table.isLoading = true;

            getAxios('other_price_groups', {
                ...this.provider && this.includeProvider && {'otherService.provider.id': this.provider.id},
                ...this.agency && this.includeAgency && {'otherService.agency.id': this.agency.id},
                ...this.otherServiceType && {'otherService.otherServiceType.id': this.otherServiceType.id},
                ...(!this.includeProvider) && {
                    'otherService.provider[exists]': false,
                },
                ...(!this.includeAgency) && {
                    'otherService.agency[exists]': false,
                },
                ...this.computedFilters,
                _groups: ['provider_price_group:provider_price', 'provider_price', 'provider_price_group:price_time_period', 'price_time_period', 'room_type', 'service_day_time'],
            }).then(response => {
                this.$refs.table.isLoading = false;

                this.pagination.items = Number(response.headers['x-trav-total-items'])
                this.prices = response.data.map(price => {
                    let providerPrices = price.providerPrices;

                    let buyPrice = providerPrices.find(price => price.priceType === 'buy_price')
                    let sellPrice = providerPrices.find(price => price.priceType === 'sell_price')
                    if(!buyPrice) {
                        providerPrices.push({
                            priceType: 'buy_price',
                            price: {
                                amount: null,
                                currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                            }
                        })
                    }
                    if(!sellPrice) {
                        providerPrices.push({
                            priceType: 'sell_price',
                            price: {
                                amount: null,
                                currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                            }
                        })
                    }
                    return {
                        ...price,
                        hasChanged: false,
                        providerPrices: providerPrices
                    }
                });

            })
        },

        changePriceGroup(row, priceGroupId) {
            //case 1: Existing element in prices needs to be moved to other priceGroup
            //find oldPriceGroup
            let prices = JSON.parse(JSON.stringify(this.prices));

            let oldPrice = prices.find(price => {
                return price.timePeriods.find(period => period.id === row.period.id)
            })
            let timePeriod = oldPrice.timePeriods.find(period => period.id === row.period.id);

            //find newPriceGroup
            let newPrice = prices.find(price => price.id === priceGroupId)
            if(!newPrice) {
                prices.push({
                    ...row.priceGroup,
                    id: priceGroupId,
                    timePeriods: [],
                    providerPrices: [{...row.buyPrice}, {...row.sellPrice}],
                });
                newPrice = prices.find(price => price.id === priceGroupId);
            }
            if(oldPrice.id !== newPrice.id) {
                newPrice.timePeriods.push(timePeriod)
            }
            //delete if oldPrice not relevant
            if(oldPrice.timePeriods.length === 1) {
                let index = prices.findIndex(price => price.id === oldPrice.id);
                prices.splice(index, 1);
            } else {
                let index = oldPrice.timePeriods.findIndex(period => period.id === row.period.id);
                oldPrice.timePeriods.splice(index, 1);
            }
            this.prices = prices;



        },

        saveRow(data) {
            let rows = JSON.parse(JSON.stringify(this.rows));

            rows = rows.map(row => {
                if(data.row.key === row.key) {
                    row.priceGroup.hasChanged = true;
                    return set(row, data.colKey, data.value)
                } else {
                    return row
                }
            })

            this.rows = rows;
            data.row = set(data.row, data.colKey, data.value);
        },

        openCallback: function(form) {
            if(this.providerType === 'provider') {
                this.provider = this.item;
            }
            if(this.providerType === 'agency') {
                this.agency = this.item;
            }
        },

        handleInitialValue: function(initialValue) {

            this.open();



            if(this.initialValue.price){
                getOtherPlaceholder(initialValue.price.placeholder.id, '?_groups[]=modal_read&_groups[]=order_placeholder_displayed_request_read').then(response => {
                    const {otherServiceType, displayedRequest} = response.data;

                    this.otherServiceType = otherServiceType;
                    this.agency = displayedRequest && displayedRequest.agency ? displayedRequest.agency : null;
                    this.provider = displayedRequest && displayedRequest.provider ? displayedRequest.provider : null;
                    if(this.agency){
                        this.priceType = 'agency';
                    } else if(this.provider){
                        this.priceType = 'provider';
                    }
                })
            }

            if(this.initialValue.conceptProvider) {
                this.form = {...this.form, ...this.initialValue.conceptProvider}
            }

            if(this.agency){
                this.priceType = 'agency';
            } else if(this.provider){
                this.priceType = 'provider';
            }
        },

        deletePeriod: function (row) {
            let prices = JSON.parse(JSON.stringify(this.prices));
            let priceGroup = prices.find(price => price.id === row.priceGroup.id);
            if(priceGroup.timePeriods.length === 1) {
                if(!priceGroup.isNew) {
                    this.priceGroupIdsToDelete.push(priceGroup.id);
                }
                let index = prices.findIndex(price => price.id === row.priceGroup.id);
                prices.splice(index, 1);
            } else {
                priceGroup.hasChanged = true;
                let index = priceGroup.timePeriods.findIndex(time => time.id === row.period.id);
                priceGroup.timePeriods.splice(index, 1);
            }
            this.prices = prices;
        },

        addPrice: function () {
            let price = {};
            if(this.initialValue) {

                if(this.initialValue.conceptProvider) {
                    price = {timePeriods: this.initialValue.conceptProvider.timePeriods, hasChanged: true};
                }

                if(this.initialValue.price) {
                    price = {
                            ...!this.initialValue.sellingPrices && {
                                timePeriods: [
                                    {
                                        startAt: '01.01' + moment(this.initialValue.price.placeholder.startAt, 'DD.MM.YYYY').format('YYYY'),
                                        endAt: '31.12.' + moment(this.initialValue.price.placeholder.endAt, 'DD.MM.YYYY').format('YYYY'),
                                        days: [0, 1, 2, 3, 4, 5, 6]
                                    }
                                ],
                            },
                            ...this.initialValue.sellingPrice && {
                                timePeriods: this.initialValue.sellingPrice.seasonTimes.map(season => {
                                    return {
                                        days: [0,1,2,3,4,5,6],
                                        key: season.id,
                                        startAt: season.startAt,
                                        endAt: season.endAt
                                    }
                                }),
                                name: this.initialValue.sellingPrice.subTitle
                            },
                            hasChanged: true,
                            providerPrices: this.defaultProviderPrices.map(price => {
                                if(price.priceType === 'buy_price') {
                                    price.price = this.initialValue.price.buyPrice;
                                }
                                if(price.priceType === 'sellPrice') {
                                    price.price = this.initialValue.price.sellPrice;
                                }
                                return price;
                            })
                        };
                }
            }

            let key = 'neu-' + (this.getNewPriceGroups.length + 1);
            let defaultTimePeriod = {
                startAt: null,
                endAt: null,
                ...numbersToDays([0,1,2,3,4,5,6]),
                id: key,
            };
            this.prices.push(
                {
                    timePeriods: [defaultTimePeriod],
                    providerPrices: this.defaultProviderPrices,
                    isNew: true,
                    period: {
                        startAt: null,
                        endAt: null,
                        days: [0,1,2,3,4,5,6],
                    },
                    name: '',
                    serviceDayTimes: [],
                    id: key,
                    ...price,
                    ...price.timePeriods && {
                        timePeriods: price.timePeriods.map(timePeriod => ({
                            ...defaultTimePeriod,
                            ...timePeriod,
                            ...numbersToDays(timePeriod.days),
                        }))
                    },
                }
            )
        },

        getIDFromString: function(apiString) {
            if(typeof apiString === 'object'){
                return apiString.id;
            }
            let stringArray = apiString.split('/');
            return stringArray[stringArray.length - 1];
        },


        prepareForBackend: function(data) {

            //data.dataCheckedAt = this.dataCheckedAtCheck;
            //data.name = this.ownName ? data.name : '';

            if(this.includeAgency && data.agency) {
                data.agency = data.agency.id ? `/api/agencies/${data.agency.id}` : data.agency;
            } else {
                data.agency = null;
            }

            if(this.includeProvider && typeof data.otherService === 'object' && data.otherService.provider) {
                data.otherService.provider = data.otherService.provider.id ? `/api/providers/${data.otherService.provider.id}` : data.otherService.provider;
            } else if(data.otherService === 'object') {
                data.otherService.provider = null;
            }

            if(data.otherService) {
                data.otherService = data.otherService.id ? `/api/other_services/${data.otherService.id}` : data.otherService;
            }

            let buyPrice = data.providerPrices.find(price => price.priceType === 'buy_price');

            data.providerPrices = data.providerPrices.map(price => {
                return Object.assign({}, price, {
                    price: {
                        amount: price.price.amount,
                        currency: buyPrice.price.currency
                    }
                })
            });
            return data;
        },

        handleSubmit: function () {

            if(this.includeProvider && !this.provider) {
                notifyError('Bitte Leistungsträger auswählen!')
                return;
            }
            if(this.includeAgency && !this.agency) {
                notifyError('Bitte Agentur auswählen!')
                return;
            }
            if(!this.otherServiceType) {
                notifyError('Bitte Leistung auswählen!')
                return;
            }



            Promise.all([
                ...this.prices.filter(price => price.hasChanged).map(price => {
                this.isLoading = true;
                let method = price.isNew ? 'post' : 'put';
                let api = 'other_price_groups';

                if(price.isNew) {
                    price = omit(price, ['id']);
                    price.provider = this.provider;
                    price.agency = this.agency;
                    price.otherServiceType = this.otherServiceType;
                    price.otherService = {
                        otherServiceType: '/api/other_service_types/' + this.otherServiceType.id,
                        ...this.includeProvider && this.provider && {provider: '/api/providers/' + this.provider.id},
                        ...this.includeAgency && this.agency && {agency: '/api/agencies/' + this.agency.id},
                        subtitle: price.otherServiceSubtitle
                    }
                    price.timePeriods = price.timePeriods.map(period => omit(period, ['id']))
                } else {
                    api += '/' + price.id
                }
                let data = this.prepareForBackend(price)

                return axiosByMethod(method, api, data, {
                    _groups: [
                        'provider_price_group:price_time_period',
                        'provider_price_group:provider_price',
                        'price_time_period',
                        'provider_price',
                        'data_check_read',
                        ...price.isNew ? ['provider_price_group:provider_other_service', 'other_service_write'] : []
                    ]
                })
            }),
                ...this.priceGroupIdsToDelete.map(priceGroup => {
                    return axiosDelete('other_price_groups/' + priceGroup)
                })
            ]).then(responses => {
                notifySuccess('Die Preise wurden gespeichert!')
                //update menus tab
                /*let api = this.item && this.item.providerType === 'ferry' ? 'ferries' : this.item.providerType+'s';
                getAxios(+ api +'/' + this.item.id, { params: {_groups: ['menu']}}).then(res => {
                    this.$store.commit('updateData', {
                        menus: res.data.menus
                    })
                })*/
                this.$emit('submit')
                this.isLoading = false;
                this.close()
            }, error => {
                this.notifyError("Die Preise konnte nicht gespeichert werden!")
                this.isLoading = false;
            })
        },

        getRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },

        getBackground (row) {
            let colors = [
                '#AAD7D9',
                '#EFBC9B',
                '#FBF3D5',
                '#D6DAC8',
                '#9CAFAA',
                '#EED3D9',
                '#B5C0D0',
                '#E5E1DA',
                '#FBF9F1',
                '#BA90C6',
                '#E8A0BF',
                '#FDF4F5',
                '#D8AE7E',
                '#9BABB8'
            ];
            let index = this.prices.findIndex(price => price.id === row.priceGroup.id)
            return 'background:' + (index < 15 ? colors[index] : this.getRandomColor());
        }

    },

    watch: {
        computedFilters: function(oldValue, newValue) {
            if(JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                this.getPrices();
            }
        },

        provider () {
            if(!this.includeProvider || this.provider) {
                this.getPrices();
            }
        },

        agency () {
            if(!this.includeAgency || this.agency) {
                this.getPrices();
            }
        },

        type () {
            this.getPrices();
        },

        'otherServiceType' () {
            if(this.otherServiceType) {
                this.getPrices();
            }
        },

        priceType () {
            if(this.priceType === 'calculation') {
                this.getPrices();
            }
            if(this.priceType === 'provider') {
                if(this.provider) {
                    this.getPrices();
                }
            }
            if(this.priceType === 'agency') {
                if(this.agency) {
                    this.getPrices();
                }
            }
        }
    }
}


</script>
